import React from 'react'
import { useInView } from 'react-intersection-observer';
import { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';

import './Servicesnew.css'

import { Link } from 'react-router-dom';
import YouTubeVideoEmbed from '../test/YouTubeVideoEmbed';
import ImageViewer from 'awesome-image-viewer';



const Servicesnew = (props) => {
    const [navbarHeight, setNavbarHeight] = useState(0);

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [videoIdToPlay, setVideoIdToPlay] = useState("");
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const playerRef = useRef(null);

    const onPlayerReady = (event) => {
        playerRef.current = event.target;
    };
    const ClickHandlerImageShow = (data) => {
        new ImageViewer({
            images: data
        })
    }

    useEffect(() => {
        // Function to calculate and set the navbar height dynamically
        const calculateNavbarHeight = () => {
            const navbar = document.getElementById('navbar'); // Replace 'navbar' with the actual ID of your navbar
            if (navbar) {
                const height = navbar.offsetHeight;
                setNavbarHeight(height);
            }
        };

        // Call the calculateNavbarHeight function when the component mounts and on window resize
        calculateNavbarHeight();
        window.addEventListener('resize', calculateNavbarHeight);

    })



    const clickHandlerButtonShow = (videoId) => {
        setIsOverlayVisible(true);
        setIsVideoPlaying(true);
        setVideoIdToPlay(videoId); // Add this line to set the video ID

        const player = document.querySelector('.youtubeVideo')
        player.classList.remove('hidden');

    }
    const clickHandlerButtonRemove = (e) => {
        if (playerRef.current && playerRef.current.internalPlayer) {
            playerRef.current.internalPlayer.pauseVideo();
        }
        setIsOverlayVisible(false);
        setIsVideoPlaying(false);
        setVideoIdToPlay("");
        const player = document.querySelector('.youtubeVideo')
        player.classList.add('hidden')

    }

    const handleImageClick = (video) => {

        clickHandlerButtonShow(video);
    };


    // ON SCROLL FOR A SERVICES BAR
    const [ref, inView] = useInView({
        triggerOnce: false, // Only trigger once when the element enters the viewport
        threshold: 0.1,   // Trigger when at least 50% of the element is visible
    });

    const containerStyles = {
        // Add other styles as needed
        position: inView ? 'fixed' : 'absolute',
        top: inView ? `${navbarHeight}px` : '0',
    };



    return (
        <section id='services-new-section'>

            <div className="servicesbar" style={containerStyles}>

                {props.data.servicebar.map((data, i) => (
                    <div className='servicebar' key={i}>
                        <a href={data.href} className='servicebar-list'>
                            {data.service}

                        </a>

                    </div>
                ))}

            </div>
            <div className='services-new--container' ref={ref}>
                <div className="services-new-details">
                    <h2> Our Services</h2>
                    <span className="underline"></span>

                </div>

                {/* 1 */}

                {/* =============================================== */}
                {props.data.servicesSection.map((data, i) => (

                    <div key={i + 15}>
                        <div className={`services-new ${data.isClass ? data.isClass : ''}`} id={data.id}>

                            <div className="service-new-container" >
                                <h3>{data.title}</h3>

                                <p>
                                    {data.description}
                                </p>

                            </div>
                            <div className='services-image-container'>

                                <img src={data.image} alt="" />
                            </div>

                        </div>

                        <div className="services1-new-container">
                            {data.randomProjects.map((el, i) => {

                                if (el.datatype === 'images') {

                                    return (

                                        <img src={el.image} alt="" key={i + 25}
                                            onClick={() => ClickHandlerImageShow(el.imageData)}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <YouTubeVideoEmbed videoId={el.videoLink} onImageClick={() => handleImageClick(el.videoLink)} key={i} />
                                    )

                                }

                            })}

                        </div>

                    </div>


                ))}



                {/* =============================================== */}



                <div className={`youtubeVideo ${isOverlayVisible ? '' : 'hidden'}`} onClick={clickHandlerButtonRemove}>
                    {isVideoPlaying && (
                        <YouTube
                            videoId={videoIdToPlay}
                            opts={{
                                width: '560',
                                height: '315',
                                playerVars: {
                                    // autoplay: 1,
                                },
                            }}
                            onReady={onPlayerReady}
                            onEnd={() => setIsVideoPlaying(false)}
                        />
                    )}
                    <Link to='/media/projects'>
                        <button className="btn" onClick={clickHandlerButtonRemove}>
                            Learn More
                        </button>
                    </Link>
                </div>

            </div>

        </section>
    )
}

export default Servicesnew