import mainImage from '../../assests/img-design/main-1.png'


const EngineeringPageMain = {
    mainTitle: 'We ARE A TEAM OF PROFESSIONAL',
    mainPara: "Welcome to our mechanical design engineering services! As a team of skilled mechanical design engineers, we offer a range of services to help bring your product ideas to life. Whether you need help with machine design, motion analysis, FEA and CFD analysis, product renders, or 3D animations, we're here to provide you with the precise and innovative solutions you need to succeed.",
    mainImage: mainImage,
    typeWriter: ["Mechanical Engineers", "3D Animators", "FEA&CFD Experts"]



}

export default EngineeringPageMain;