import { useEffect, useState } from 'react';
const apiKey = 'AIzaSyBcIYuA89YeP6HiNl-P1cj-gqNg2CaZW_c';

const YouTubeVideoEmbed = ({ videoId, onImageClick }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (!videoId || !apiKey) {
            return;
        }

        // Fetch video information from the YouTube Data API.
        fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`)
            .then((response) => response.json())
            .then((data) => {
                if (data.items && data.items.length > 0) {
                    const img = data.items[0].snippet.thumbnails.high.url;
                    setImageUrl(img);
                }
            })
            .catch((error) => {
                console.error('Error fetching video data:', error);
            });
    }, [videoId]);

    // console.log(imageUrl);
    return (
        <img src={imageUrl} alt="Video Thumbnail" onClick={onImageClick} />
    );
}
export default YouTubeVideoEmbed;
