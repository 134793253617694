import { useState } from 'react'
import React from 'react'
import './ContactUsPage.css'
import { CgMail } from 'react-icons/cg'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import axios from 'axios'

const ContactUsPage = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();


        console.log(formData);

        const data = {
            First_Name: formData.firstName,
            Last_Name: formData.lastName,
            Email: formData.email,
            Message: formData.message,
        }

        axios.post('https://sheet.best/api/sheets/7437a9c2-e527-49a9-9791-178a60f61c48', data)
            .then((res) => {
                console.log(res);

                // clearing form fields

                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    message: ''

                })
            })
    };


    return (
        <section className="contactus" id="contactus">
            <div className="contact__aside">
                <div className="aside__image">
                    <img src='https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021286/Calling-on-phone-with-vutuk-logo_elc2ly.png' alt="" />
                </div>
                <div className="aside__details">
                    <h2>Contact Us</h2>
                    <p>Get in Touch</p>
                </div>
                <div className="contact__aside-socials">

                    <div className="contact__aside-social">
                        <a href="mailto:contact@vutuk.com" target='_blank' rel="noopener noreferrer" >
                            <CgMail className='socials-icon-gmail' />
                        </a> contact@vutuk.com
                    </div>
                    <div className="socials">

                        <a href="https://www.instagram.com/iamvutuk/" target='_blank' rel="noopener noreferrer">
                            <FaInstagram className='socials-icon' />

                        </a>

                        <a href="https://www.linkedin.com/company/vutuk/" target='_blank' rel="noopener noreferrer">
                            <FaLinkedin className='socials-icon' />
                        </a>

                        <a href="https://twitter.com/iamvutuk" target='_blank' rel="noopener noreferrer">
                            <FaTwitter className='socials-icon' />
                        </a>

                        <a href="https://www.facebook.com/iamvutuk/" target='_blank' rel="noopener noreferrer">
                            <FaFacebook className='socials-icon' />
                        </a>
                    </div>
                </div>
            </div>
            <div className="contact__form">
                <div className="empty"></div>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="form__name">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form__details">
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter Email Address"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            name="message"
                            cols="30"
                            rows="10"
                            placeholder="Enter Your Message Here"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn">Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default ContactUsPage