import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Main from '../../components/main/Main'
// import Services from '../../components/services/Services'
import SwitchLogoDesign from '../../assests/logo/switchDesign.png'

import mediaPageMain from './MediaPageMain'
// import MediaTestimonialsPage from '../../components/testimonials/MediaTestimonialsPage'

import Footer from '../../components/footer/Footer'

import mediaLogo from '../../assests/logo/Vutuk_Media_white_logo.png'
import CTA from '../../components/cta/CTA'
import { Link } from 'react-router-dom'
import Clients from '../../components/clients/Clients'
import Servicesnew from '../../extra/Servicesnew'
import serviceMedia from '../../components/data/serviceMedia'


const MediaPage = () => {
    return (
        <>
            <Navbar
                navlogo={'/media'}
                logo={mediaLogo}
                home={'/media'}
                about={'/media/about'}
                projects={'/media/projects'}
                contact={'/media/contact'}
            />
            <Main
                mainTitle={mediaPageMain.mainTitle} mainPara={mediaPageMain.mainPara}
                mainImage={mediaPageMain.mainImage}
                typeWriter={mediaPageMain.typeWriter}
                redirectContact={'/media/contact'}
                redirectProject={'/media/projects'}
                mainVideoId={'bQiskp910_0'}
            />
            <Servicesnew
                data={serviceMedia}
            />



            {/* <Services
                servicesPara={mediaPageMain.servicesPara}
                // ICONS
                servicesContainer1Icon={mediaPageMain.servicesContainer1Icon}
                servicesContainer2Icon={mediaPageMain.servicesContainer2Icon}
                servicesContainer3Icon={mediaPageMain.servicesContainer3Icon}
                servicesContainer4Icon={mediaPageMain.servicesContainer4Icon}
                servicesContainer5Icon={mediaPageMain.servicesContainer5Icon}
                servicesContainer6Icon={mediaPageMain.servicesContainer6Icon}

                // HEADINGS
                servicesContainer1Title={mediaPageMain.servicesContainer1Title}
                servicesContainer2Title={mediaPageMain.servicesContainer2Title}
                servicesContainer3Title={mediaPageMain.servicesContainer3Title}
                servicesContainer4Title={mediaPageMain.servicesContainer4Title}
                servicesContainer5Title={mediaPageMain.servicesContainer5Title}
                servicesContainer6Title={mediaPageMain.servicesContainer6Title}

                // PARA
                servicesContainer1Para={mediaPageMain.servicesContainer1Para}
                servicesContainer2Para={mediaPageMain.servicesContainer2Para}
                servicesContainer3Para={mediaPageMain.servicesContainer3Para}
                servicesContainer4Para={mediaPageMain.servicesContainer4Para}
                servicesContainer5Para={mediaPageMain.servicesContainer5Para}
                servicesContainer6Para={mediaPageMain.servicesContainer6Para}

            /> */}


            <Clients />


            {/* <MediaTestimonialsPage /> */}

            <Footer
                home={'/media'}
                about={'/media/about'}
                projects={'/media/projects'}
                contact={'/media/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'} />
            <Link to={'/design'}>
                <CTA logoImage={SwitchLogoDesign} />

            </Link>
        </>
    )
}

export default MediaPage