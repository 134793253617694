

const clientsLogo = [
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021104/08_uxilf7.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021105/06_fu3270.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021104/10_qjtzmi.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021105/09_vjwgpg.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021104/07_hprpah.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021103/03_tvplvs.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021104/05_we65yz.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021103/04_t5wwg9.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021103/01_sa37ov.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021103/02_jcmc94.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021103/11_ydbsdv.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021182/13_rs1g5d.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021182/12_ygvjqy.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021181/10_lcwtsi.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021181/11_p6tiuh.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021181/08_s8fywt.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021180/05_bg8hvf.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021180/09_v88a7a.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021180/07_by6mh4.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021179/06_mtegml.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021179/04_fvkr4h.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021179/03_bm8ipy.png',

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021179/02_mntucp.png',
    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021179/01_oqekpq.png',

    },


]

export default clientsLogo;