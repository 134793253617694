import React from 'react'
import './Projects.css'
import MediaProjectsPage from './MediaProjectsPage'
import './ProjectsPage.css'
import Navbar from '../../components/navbar/Navbar'
import logoMedia from '../../assests/logo/Vutuk_Media_white_logo.png'
import { Link } from 'react-router-dom'
import CTA from '../../components/cta/CTA'
import SwitchLogoDesign from '../../assests/logo/switchDesign.png'


const Projects = () => {
    return (
        <>
            <Navbar logo={logoMedia}
                navlogo={'/media'}
                home={'/media'}
                about={'/media/about'}
                projects={'/media/projects'}
                contact={'/media/contact'} />
            <MediaProjectsPage />

            <Link to='/design'>

                <CTA logoImage={SwitchLogoDesign} />
            </Link>
        </>
    )
}

export default Projects