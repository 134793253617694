import React from 'react'
import clientsLogo from '../../components/data/clients_logo';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// CUSTOM CSS
import './Clients.css'
// import required modules
import { Autoplay, Navigation } from 'swiper/modules';

const Clients = (props) => {
    return (


        <div className="clients--container">
            <h2>Our Clients</h2>
            <Swiper
                slidesPerView={8}
                spaceBetween={20}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}

                pagination={{
                    clickable: true,
                }}
                breakpoints={{

                    320: {
                        width: 320,
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    375: {
                        width: 375,
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    425: {
                        width: 425,
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },

                    768: {
                        width: 768,
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 50,
                    }
                }}

                navigation={true}
                modules={[Autoplay, Navigation]}
                className="clients--logo-container mySwiper">

                {clientsLogo.map((el, i) => {
                    return (
                        <SwiperSlide key={i} className="clients--logo" ><img src={el.image} alt="" /></SwiperSlide>
                    )
                })}

            </Swiper>


        </div>
    )
}

export default Clients