import React from 'react'
import './Projects.css'
import ProjectsEngineeringMainPage from './EngineeringProjectsPage'
import './ProjectsPage.css'
import Navbar from '../../components/navbar/Navbar'
import logoDesign from '../../assests/logo/Vutuk-Logo_design.png'
import { Link } from 'react-router-dom'
import CTA from '../../components/cta/CTA'

import SwitchLogoDesign from '../../assests/logo/SwitchMedia.png'


const Projects = () => {
    return (
        <>
            <Navbar logo={logoDesign}
                navlogo={'/design'}
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'} />
            <ProjectsEngineeringMainPage />
            <Link to='/media'>

                <CTA logoImage={SwitchLogoDesign} />
            </Link>


        </>
    )
}

export default Projects