
const engineeringProjectImage_data = [

    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_6_ugf7dg.jpg',
        title: "3D printing Robot Design",
        para: "The '3D Printing Robot Design' project focuses on creating a versatile and automated robot for 3D printing applications. This design incorporates a precise extrusion system, advanced control software, and a robust frame to enable accurate and efficient 3D printing of various materials. The 3D printing robot is intended for use in manufacturing, prototyping, and construction, allowing for the creation of complex and customized objects with ease and precision.",
        type: 'Machine-ProductDesign', datatype: 'images',

        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_6_ugf7dg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_5_vjobin.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_8_q7bjex.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_3_neisbw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_2_j3mr3z.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842420/3D-Printing-Robot_1_bpaqmt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_7_bua4rc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842419/3D-Printing-Robot_7_bua4rc.jpg'
                },



            ]

    },

    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_3_ulatuo.jpg',
        title: "Wearable Smart Air conditioned Waist ",
        para: "The 'Wearable Smart Air-Conditioned Waist' project involves designing a wearable device that provides personal climate control to the user's waist area. This innovative wearable may incorporate advanced cooling and heating elements, such as Peltier modules or thermoelectric materials, along with smart sensors to monitor body temperature and adjust the climate accordingly. It aims to offer comfort and thermal regulation for individuals in various environments, from outdoor activities to office settings, enhancing overall well-being and productivity.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842817/Air_conditioning_waist_2_lltwao.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_6_bt0s5e.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_1_cpqlcg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_5_ctmmee.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_4_tisi8q.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694842816/Air_conditioning_waist_3_ulatuo.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843056/Air_Purifier_Design_2_bt8fvg.jpg',
        title: "Air Purifier Design",
        para: "The 'Air Purifier Design' project involved developing a cutting-edge air purification system. This design incorporated high-efficiency particulate air (HEPA) filters, advanced filtration technologies, and smart sensors to monitor and improve indoor air quality. The air purifier was intended to remove allergens, pollutants, and harmful particles, providing clean and healthy air for residential, commercial, or industrial spaces, thereby enhancing the well-being of occupants and contributing to a cleaner environment.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843056/Air_Purifier_Design_2_bt8fvg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843056/Air_Purifier_Design_1_vxq9ir.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843056/Air_Purifier_Design_3_toeq2w.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843055/Air_Purifier_Design_4_jlelzr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843055/Air_Purifier_Design_5_zgjseh.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843200/BrakePedal_3_zc3wpy.jpg',
        title: "Brake pedal Design",
        para: "The 'Brake Pedal Design' project focused on creating an effective and ergonomic brake pedal for vehicles. This design prioritized safety, usability, and driver comfort. It incorporated features like anti-slip surfaces, adjustable positioning, and an appropriate mechanical linkage to ensure responsive braking. The brake pedal design aimed to provide precise control and reliable stopping power for various vehicle types, contributing to overall road safety and driver confidence.gn",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843200/BrakePedal_3_zc3wpy.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843200/BrakePedal_2_u7le9j.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843200/BrakePedal_1_ythzsr.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843547/Centennial_Pump_Design_7_rxygmp.jpg',
        title: "Centennial Pump Design",
        para: "The 'Centennial Pump Design' project involved the creation of a specialized pump system designed to commemorate or celebrate a centennial event or milestone. This unique pump design could be tailored to serve various purposes, such as water fountains, decorative displays, or industrial applications, while incorporating design elements that reflect the significance of the centennial occasion. It aimed to merge functionality with aesthetics to mark a hundred years of achievement or history in a memorable and impactful way.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843544/Centennial_Pump_Design_3_ejfezq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843544/Centennial_Pump_Design_5_d8ut0n.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843545/Centennial_Pump_Design_6_bszrkz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843545/Centennial_Pump_Design_4_ones4d.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843546/Centennial_Pump_Design_1_w0hpzq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843547/Centennial_Pump_Design_7_rxygmp.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843547/Centennial_Pump_Design_2_fveqy7.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843678/Mouse-Design_1_bjwuwz.jpg',
        title: "Computer Mouse Design",
        para: "The 'Computer Mouse Design' project involved creating an ergonomic and user-friendly computer mouse. This design prioritizes comfort, accuracy, and user experience. It features a sleek and intuitive shape, responsive buttons, and precise tracking technology to enhance productivity and reduce strain during long hours of computer use. The project aimed to deliver a reliable and aesthetically pleasing mouse suitable for a wide range of computer users.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843678/Mouse-Design_1_bjwuwz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843678/Mouse-Design_2_kh4rf2.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843678/Mouse-Design_3_yoycy3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843678/Mouse-Design_4_rjborn.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843810/Dragon-Toy-Mechanism_3_up4v7g.jpg',
        title: "Dragon Toy motion mechanism",
        para: "The 'Dragon Toy Motion Mechanism' project focused on designing a captivating and interactive toy with a lifelike dragon motion mechanism. This design featured articulated joints, wings, and possibly animatronic elements to mimic the movement of a dragon, offering an engaging and imaginative play experience for children and enthusiasts. The motion mechanism aimed to bring the dragon toy to life, allowing it to move, flap its wings, and create a sense of wonder and fantasy for users.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843810/Dragon-Toy-Mechanism_3_up4v7g.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843810/Dragon-Toy-Mechanism_2_cgck1u.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843811/Dragon-Toy-Mechanism_4_pzdedm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843811/Dragon-Toy-Mechanism_1_dqnsi7.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843910/Dragon-Wings-Solar_2_lv0anr.jpg',
        title: "Dragon Wings Solar panel Design",
        para: "The 'Dragon Wings Solar Panel Design' project involved the development of innovative solar panels designed to resemble dragon wings. These panels were not only functional for harnessing solar energy but also featured an aesthetically pleasing and unique design, resembling dragon scales or wings. This design aimed to combine sustainable energy generation with artistry, making solar panels an attractive and creative addition to homes and structures while contributing to renewable energy production.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843911/Dragon-Wings-Solar_3_pibogs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843912/Dragon-Wings-Solar_1_s0niat.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694843910/Dragon-Wings-Solar_2_lv0anr.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694844007/E-Bike_3_jq6pr2.jpg',
        title: "E-Bike Commuter Design",
        para: "The 'E-Bike Commuter Design' project aimed to create an electric bicycle tailored for daily commuting.This design incorporated an electric motor for pedal assistance, a lightweight frame, long- lasting battery technology, and smart features such as GPS integration and smartphone connectivity.The E - Bike Commuter was designed to provide an eco - friendly and efficient alternative for urban transportation, reducing the carbon footprint while offering convenience and flexibility to commuters on their daily journeys.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694844007/E-Bike_3_jq6pr2.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694844008/E-Bike_4_jqemig.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694844008/E-Bike_2_ckxwv3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694844010/E-Bike_1_kls5xi.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853847/ElectricHeaterDesign_4_cjesr5.jpg',
        title: "Electric heater Design",
        para: "The 'Electric Heater Design' project involved developing an energy-efficient and reliable electric heating device. This design focused on achieving rapid and even heating, safety features like temperature control and overheating protection, and user-friendly controls. It aimed to provide efficient space heating solutions for various applications, from residential homes to industrial settings, ensuring warmth and comfort while minimizing energy consumption and promoting safety.", datatype: 'images',
        type: 'Machine-ProductDesign',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853847/ElectricHeaterDesign_4_cjesr5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853847/ElectricHeaterDesign_1_xvilvn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853847/ElectricHeaterDesign_3_nlifjg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853847/ElectricHeaterDesign_2_a2ubwp.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853939/Enclosure-Design_3_rka05z.jpg',
        title: "Enclosure design for electronics Device",
        para: "The 'Enclosure Design for Electronics Device' project centered around creating a protective and functional housing for electronic devices. This design incorporated considerations for factors like heat dissipation, electromagnetic interference (EMI) shielding, and user interface accessibility. It aimed to ensure the safety, longevity, and optimal performance of the enclosed electronics while maintaining an aesthetically pleasing and user-friendly design. The enclosure design was tailored to meet the specific needs of the electronic device and the preferences of its target users.", datatype: 'images',
        type: 'Machine-ProductDesign',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853939/Enclosure-Design_3_rka05z.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853939/Enclosure-Design_2_pf5eun.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853939/Enclosure-Design_1_xtahac.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694853939/Enclosure-Design_4_upr4uo.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/ExBike_4_tvqyze.jpg',
        title: "Excercise Bike Design",
        para: "The 'Exercise Bike Design' project involved developing a stationary exercise bike with a focus on user comfort, performance monitoring, and convenience. This design featured adjustable resistance levels, ergonomic seating, and a user-friendly digital display for tracking workout metrics such as distance, speed, and heart rate. The exercise bike aimed to provide an effective and enjoyable cardiovascular workout experience for users in the comfort of their homes or fitness facilities.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854028/ExBike_2_uvxsnl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854028/ExBike_1_pd3qkg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854028/ExBike_3_k5635p.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/Exbike2.142_mmjezj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/ExBike_4_tvqyze.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/Exbike3.143_adhoqm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/Exbike1.141_h9cehq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854027/ExBike_5_saujry.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854244/Experimental_Rigs_Design_5_hgrrem.jpg',
        title: "Experimental Rigs Design",
        para: "The 'Experimental Rigs Design' project focused on creating specialized rigs for conducting experimental research and testing in various fields of science and engineering. These rigs typically feature precise control systems, data collection instrumentation, and safety measures to ensure controlled and repeatable experiments. The design aimed to meet specific research objectives, whether in materials science, fluid dynamics, or other scientific domains, by providing a platform for researchers to conduct experiments, gather data, and advance their understanding of various phenomena.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854244/Experimental_Rigs_Design_5_hgrrem.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854244/Experimental_Rigs_Design_3_dvfz7i.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854244/Experimental_Rigs_Design_1_oocx2j.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_11_xjanuc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_2_ue3161.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_4_msnhha.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_8_eggpia.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_10_iectv9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_6_nhbmzc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854243/Experimental_Rigs_Design_9_mjlrq5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854242/Experimental_Rigs_Design_7_k8fg6r.jpg'
                },
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854380/Face_NeckMasssager_1_jky5j1.jpg',
        title: "Face & Neck Massager- Renuvi Touch",
        para: "The 'Face & Neck Massager - Renuvi Touch' project involved designing a specialized massaging device for facial and neck relaxation and rejuvenation. This innovative device featured gentle massage nodes and customizable settings to provide a soothing and effective massage experience. The Renuvi Touch aimed to promote relaxation, relieve muscle tension, and improve blood circulation in the face and neck, contributing to a refreshed and revitalized appearance for users.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854380/Face_NeckMasssager_1_jky5j1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854380/Face_NeckMasssager_6_hb1w1k.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854379/Face_NeckMasssager_4_ap7wxc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854379/Face_NeckMasssager_3_djcuix.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854379/Face_NeckMasssager_5_q2oypv.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854379/Face_NeckMasssager_2_yfkfw4.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854482/Foam-Cutter-Design_3_awu6ew.jpg',
        title: "Foam Cutter Design",
        para: "The 'Foam Cutter Design' project focused on creating a versatile and precise machine for cutting foam materials. This design incorporated features such as adjustable cutting temperatures, cutting wire tension control, and a stable work surface. It was intended for various applications, including crafting, prototyping, and packaging, offering a convenient and efficient solution for accurately shaping foam materials for different purposes.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854482/Foam-Cutter-Design_3_awu6ew.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854482/Foam-Cutter-Design_4_fwckdt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854481/Foam-Cutter-Design_6_kio83l.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854481/Foam-Cutter-Design_1_paa2dw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854480/Foam-Cutter-Design_5_nkhkih.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854480/Foam-Cutter-Design_2_jkhcip.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854580/Forestry_Forwarder_RC_controlled_Design_1_adoppc.jpg',
        title: "Forestry Forwarder RC controlled Design",
        para: "The 'Forestry Forwarder RC Controlled Design' project entailed designing a remote-controlled (RC) forestry forwarder for timber harvesting and transportation in challenging forest environments. This specialized machine featured rugged construction, articulated steering, and a precise RC control system. It was intended to improve efficiency and safety in forestry operations by allowing operators to remotely maneuver through dense forests, collect logs, and transport them to a central location, reducing the need for manual labor and minimizing environmental impact.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854580/Forestry_Forwarder_RC_controlled_Design_1_adoppc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854580/Forestry_Forwarder_RC_controlled_Design_2_sgr60s.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854579/Forestry_Forwarder_RC_controlled_Design_5_imsvxd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854580/Forestry_Forwarder_RC_controlled_Design_4_nnkvwg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854579/Forestry_Forwarder_RC_controlled_Design_3_wndpyn.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854720/GearBoxElevator_1_yyriog.jpg',
        title: "Gear box Design for Elevator",
        para: "The 'Gearbox Design for Elevator' project involved creating a specialized gearbox system tailored for elevator applications. This gearbox design played a critical role in transmitting power efficiently and safely between the elevator motor and the elevator car. It featured precise gear ratios, robust materials, and advanced safety mechanisms to ensure smooth and reliable elevator operation. The design focused on optimizing performance, reducing noise, and enhancing the overall safety of elevator systems for passenger and cargo transportation.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854720/GearBoxElevator_1_yyriog.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854719/GearBoxElevator_2_s7my4a.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854719/GearBoxElevator_3_yym2p5.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854802/Eagle_2_v3z4kf.jpg',
        title: "Humanoid Robot Design for Indus Internation",
        para: "The 'Humanoid Robot Design for Indus International' project was centered around developing a humanoid robot with advanced capabilities for use in industrial applications. This robot design incorporated cutting-edge technologies such as artificial intelligence, computer vision, and dexterous manipulators. It was intended to assist with various tasks in industrial settings, including complex assembly, quality control, and material handling, ultimately improving efficiency and safety in manufacturing processes at Indus International.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854802/Eagle_2_v3z4kf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854801/Eagle_1_ghrft8.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854800/Eagle_3_kkrjym.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854800/Eagle_4_sd6yso.jpg'
                }
            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854951/Hydraulic-Drill-rig_8_tvzbkd.jpg',
        title: "Hydraulic Drilling Rig Design",
        para: "The 'Hydraulic Drilling Rig Design' project involved creating a powerful and efficient drilling machine for various applications, such as construction, mining, or geotechnical engineering. This design featured a robust hydraulic system to provide the necessary force for drilling into the ground or rock formations. It also included advanced control systems, safety features, and customizable drilling tooling to cater to specific drilling needs, ensuring reliable and precise performance in demanding environments.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854951/Hydraulic-Drill-rig_8_tvzbkd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854950/Hydraulic-Drill-rig_10_yvuslr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854950/Hydraulic-Drill-rig_9_upza8d.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854949/Hydraulic-Drill-rig_1_ngkoes.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854948/Hydraulic-Drill-rig_7_felow3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854947/Hydraulic-Drill-rig_6_yihjzn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854947/Hydraulic-Drill-rig_2_zdarrj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854946/Hydraulic-Drill-rig_3_nnbgll.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854946/Hydraulic-Drill-rig_5_tzw7ik.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694854946/Hydraulic-Drill-rig_4_y8p7is.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855085/Hose-reel_3_xezs50.jpg',
        title: "Hydraulic Hose Reel Design",
        para: "The 'Hydraulic Hose Reel Design' project aimed to create a robust and efficient hose reel system for managing hydraulic hoses in industrial and automotive applications. This design featured a durable construction with smooth and reliable hose retraction and extension mechanisms. It was engineered to enhance workplace safety and organization by preventing hose tangles, kinks, and damage, ensuring the efficient deployment and storage of hydraulic hoses in various work environments.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855085/Hose-reel_3_xezs50.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855084/Hose-reel_2_pfkalq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855083/Hose-reel_1_rqedcw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855083/Hose-reel_7_jwu5zm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855082/Hose-reel_4_rru3ab.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855081/Hose-reel_6_gqmvqy.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855080/Hose-reel_5_iprk5j.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855205/LEDBulb_3_rmrsou.jpg',
        title: "LED Bulb Design",
        para: "The 'LED Bulb Design' project aimed to develop an energy-efficient and environmentally friendly LED (Light Emitting Diode) bulb. This design focused on optimizing illumination quality, longevity, and energy savings. It incorporated advanced LED technology, heat dissipation systems, and user-friendly features like dimmability and compatibility with standard fixtures. The LED bulb design contributes to reducing energy consumption and provides long-lasting, high-quality lighting solutions for various applications, from homes to commercial spaces.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855205/LEDBulb_3_rmrsou.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855204/LEDBulb_2_l5cwjj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855204/LEDBulb_1_ng15pb.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855315/Skull-Mechanical_1_johtpx.jpg',
        title: "Mechanical Skull Design",
        para: "The 'Mechanical Skull Design' project involved the creation of a unique and artistic mechanical skull sculpture. This intricate design blends mechanical and steampunk aesthetics, featuring moving gears, cogs, and other mechanical elements. While primarily decorative, it serves as a captivating and visually striking piece of art, showcasing the fusion of engineering and artistic creativity.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855315/Skull-Mechanical_1_johtpx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855314/Skull-Mechanical_2_bhe1dq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855313/Skull-Mechanical_4_w0cipd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855312/Skull-Mechanical_3_utlsfq.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855394/MusicBox_4_dt7vbd.jpg',
        title: "Meditation Music Box Design",
        para: "The 'Meditation Music Box Design' project focused on crafting a portable and tranquil music box tailored for meditation and relaxation.This design incorporates soothing melodies and calming sounds, along with adjustable volume and timer settings.It aims to enhance the meditation experience by creating a serene atmosphere and promoting mindfulness.The compact and aesthetically pleasing design ensures that users can easily incorporate this music box into their meditation routines or relaxation practices.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855394/MusicBox_4_dt7vbd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855395/MusicBox_2_sii3se.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855398/MusicBox_5_hcf8wn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855398/MusicBox_7_nlc9oo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855399/MusicBox_1_hal54g.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855400/MusicBox_8_xq0ppt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855415/MusicBox_6_s7s4yx.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855517/Micro-Hydro-System_1_ggcdqi.jpg',
        title: "Micro-Hydro System Design",
        para: "The 'Micro-Hydro System Design' project involved creating a small-scale hydroelectric power generation system. This design harnessed the energy from flowing water in a river or stream to generate electricity for off-grid or remote locations. It included components like a water turbine, generator, control systems, and infrastructure for power distribution. This micro-hydro system aimed to provide sustainable and renewable energy solutions in areas with access to flowing water, contributing to environmental sustainability and energy self-sufficiency.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855517/Micro-Hydro-System_1_ggcdqi.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855516/Micro-Hydro-System_2_olfkti.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855515/Micro-Hydro-System_3_ddiatx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855514/Micro-Hydro-System_4_dkqknv.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855512/Micro-Hydro-System_5_l98jdh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855511/Micro-Hydro-System_6_s1r8wg.jpg'
                },




            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855621/Mini-Fridge_3_vgrk0p.jpg',
        title: "Mini Coke Cooler 2 coke at a time ",
        para: "The 'Mini Coke Cooler for Two Cokes at a Time' project aimed to design a small and portable beverage cooler specifically tailored to accommodate two Coca-Cola cans simultaneously. This innovative cooler utilizes efficient cooling technology and a compact form factor, making it ideal for personal use, picnics, or small gatherings. It ensures that users can enjoy chilled Coca-Cola beverages conveniently and on-the-go.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855620/Mini-Fridge_2_qo1qrs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855621/Mini-Fridge_3_vgrk0p.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855623/Mini-Fridge_4_qgkz8i.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855624/Mini-Fridge_1_rguk1y.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855625/Mini-Fridge_5_puuilb.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855781/MultiSpindleCNCMachine_5_mvxbqh.jpg',
        title: "Multi Spindle CNC Machine Design",
        para: "The 'Multi Spindle CNC Machine Design' project focused on developing a sophisticated CNC (Computer Numerical Control) machine with multiple spindles. This design allows for simultaneous machining of multiple workpieces, significantly improving production efficiency in industries like automotive, aerospace, and manufacturing. The machine incorporates advanced control systems, precision engineering, and tool-changing capabilities to optimize machining processes and reduce production times.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855781/MultiSpindleCNCMachine_5_mvxbqh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855780/MultiSpindleCNCMachine_1_xmgk6o.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855779/MultiSpindleCNCMachine_4_scnkzt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855778/MultiSpindleCNCMachine_3_e6m9bg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855778/MultiSpindleCNCMachine_2_kndnty.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855911/PedalCarKid_3_kmknt5.jpg',
        title: "Pedal Car design For kid",
        para: "The 'Pedal Car Design for Kids' project aimed to create a fun and safe pedal-powered vehicle tailored for children. This design prioritized child-friendly features, including ergonomic seating, easy-to-use pedals, and robust construction. The pedal car is designed to promote outdoor play, exercise, and imaginative adventures for kids while ensuring safety and durability for worry-free parental supervision.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855911/PedalCarKid_3_kmknt5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855910/PedalCarKid_2_c0460z.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855909/PedalCarKid_1_rkdarb.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855982/PipeInspectionRobot_2_dvfaby.jpg',
        title: "Pipe Inspection Robot",
        para: "The 'Pipe Inspection Robot' project involved the design and development of a specialized robotic system for inspecting pipelines. Equipped with advanced sensors and cameras, this robot can navigate through pipes of various sizes and materials, detecting defects, corrosion, or blockages. Its remote operation capability and data gathering provide valuable information to industries like oil and gas, water utilities, and infrastructure maintenance, helping to ensure the integrity and safety of pipelines.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855982/PipeInspectionRobot_2_dvfaby.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855982/PipeInspectionRobot_1_hqjkgo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694855981/PipeInspectionRobot_3_bu8ml3.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856045/WasteWaterPlantPoratble_4_wqu1fy.jpg',
        title: "Portable Plant for waste water treatment",
        para: "The 'Portable Plant for Wastewater Treatment' project involved designing a compact and mobile wastewater treatment system. This innovative solution is capable of efficiently purifying wastewater, making it suitable for various applications, such as disaster relief, remote construction sites, or temporary event facilities. Its portability and scalability provide an environmentally friendly and cost-effective solution for wastewater management in diverse settings.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856045/WasteWaterPlantPoratble_4_wqu1fy.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856044/WasteWaterPlantPoratble_1_ypupvo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856043/WasteWaterPlantPoratble_3_tmdz8t.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856042/WasteWaterPlantPoratble_2_nl7cg4.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856198/Power-Converter_1_w4ehuo.jpg',
        title: "Power Converter Design",
        para: "The 'Power Converter Design' project focused on developing efficient and reliable power conversion systems. These systems are essential for converting electrical energy from one form to another, such as from DC to AC or vice versa, while maintaining high efficiency and minimal losses. The design aimed to address specific requirements for applications ranging from renewable energy systems (like solar and wind) to electric vehicles, ensuring stable and optimized power conversion processes to meet diverse energy needs.", datatype: 'images',
        type: 'Machine-ProductDesign',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856198/Power-Converter_1_w4ehuo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856197/Power-Converter_2_gukdxx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856195/Power-Converter_4_htj4hg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694856194/Power-Converter_3_u8e2u3.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857099/Jaw-mechanism_2_g3scj1.jpg',
        title: "Robot Head Jaw Mechanism",
        para: "The 'Robot Head Jaw Mechanism' project involved designing and implementing a sophisticated jaw mechanism for a robot's head. This mechanism enables the robot to simulate natural jaw movements, including opening, closing, and articulating for realistic human-like interactions. It finds applications in robotics for entertainment, social interactions, or medical training, providing lifelike facial expressions and communication abilities to enhance human-robot interaction.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857099/Jaw-mechanism_2_g3scj1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857098/Jaw-mechanism_3_o43cfa.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857097/Jaw-mechanism_1_ndvgq5.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857263/Exercise-chair-render.198_kyuqgg.jpg',
        title: "Smart Office Exercise Chair Design",
        para: "The 'Smart Office Exercise Chair Design' project involved creating an ergonomic office chair equipped with smart features to encourage physical activity during the workday. This innovative chair includes integrated sensors and software that provide posture feedback, suggest exercise routines, and even offer gentle movements to reduce sedentary behavior. It's designed to promote better health and productivity by seamlessly incorporating physical activity into the daily office routine.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857263/Exercise-chair-render.198_kyuqgg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857265/Exercise-chair-render.197_sxegxs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857261/OfficeExcerciseChair_1_huowow.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857260/OfficeExcerciseChair_2_ls4py4.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857258/OfficeExcerciseChair_3_oi4xxl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857257/Exercise-chair-render.196_saunq6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857257/OfficeExcerciseChair_4_yqundh.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857518/Smart-Sensor_1_z8unur.jpg',
        title: "Smart Sensor Design",
        para: "The 'Smart Sensor Design' project involved the development of advanced sensor technology integrated with data processing capabilities. These sensors are designed to gather real-time data, analyze it, and provide valuable insights for various applications such as environmental monitoring, industrial automation, and healthcare. The design encompasses miniaturization, energy efficiency, and wireless connectivity to enable seamless integration into smart systems and IoT (Internet of Things) networks.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857518/Smart-Sensor_1_z8unur.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857518/Smart-Sensor_3_c8ugrx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857516/Smart-Sensor_2_wfqpwa.jpg'
                }

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857611/Solidworks_Surface_Modelling_1_fwlvut.jpg',
        title: "Solidworks Surface Modelling",
        para: "The 'SolidWorks Surface Modeling' project focused on using SolidWorks, a 3D computer-aided design (CAD) software, to create complex and freeform surface geometry. This precise modeling technique is essential for designing products with intricate and organic shapes, such as consumer electronics, automotive components, or industrial equipment, where aesthetic appeal and aerodynamics are crucial design considerations.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857611/Solidworks_Surface_Modelling_1_fwlvut.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857610/Solidworks_Surface_Modelling_4_ace9lj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857608/Solidworks_Surface_Modelling_3_cdwvpm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857607/Solidworks_Surface_Modelling_5_lzueqz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857606/Solidworks_Surface_Modelling_2_xbcq6a.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857737/Steam_Cloth_Care_Closet_2_kb2z4a.jpg',
        title: "Steam Cloth Care Closet Design",
        para: "The 'Steam Cloth Care Closet Design' project involved designing a compact, automated closet for clothing care. This innovative solution utilizes steam technology to remove wrinkles, refresh fabrics, and eliminate odors from garments. It's equipped with intelligent controls and garment-specific settings, making it a convenient and time-saving addition to modern households, ensuring clothes always look their best with minimal effort.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857739/Steam_Cloth_Care_Closet_3_s1yhsb.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857738/Steam_Cloth_Care_Closet_4_et4hwt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857737/Steam_Cloth_Care_Closet_2_kb2z4a.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857733/Steam_Cloth_Care_Closet_1_neenaq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857731/Steam_Cloth_Care_Closet_9_cwcyzo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857729/Steam_Cloth_Care_Closet_5_d4ba1x.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857728/Steam_Cloth_Care_Closet_7_s0kxfu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857726/Steam_Cloth_Care_Closet_8_xhcvkl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857724/Steam_Cloth_Care_Closet_6_awat3k.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857832/Swing-Saw-Machine_2_ybryf7.jpg',
        title: "Swing Saw Machine Design",
        para: "The 'Swing Saw Machine Design' project entailed creating a versatile woodworking tool capable of making precise and angular cuts in various materials. This swing saw machine features a pivoting blade mechanism, allowing it to make both straight and angled cuts with accuracy. Its design caters to woodworkers, carpenters, and craftsmen, providing them with a valuable tool for intricate and efficient cutting tasks in their projects.",
        type: 'Machine-ProductDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857832/Swing-Saw-Machine_2_ybryf7.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857830/Swing-Saw-Machine_1_sqmau8.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857830/Swing-Saw-Machine_3_lvredg.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857938/Tooth_Gum_Massager_Design_9_n346fa.jpg',
        title: "Tooth Gum Massager Design",
        para: "The 'Tooth Gum Massager Design' project involved the development of a user-friendly dental device designed to gently massage and stimulate the gums for improved oral health. This innovative tool enhances circulation, reduces gum discomfort, and promotes better gum hygiene, making it an ideal addition to dental care routines for individuals seeking enhanced gum wellness and overall oral hygiene.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857938/Tooth_Gum_Massager_Design_9_n346fa.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857936/Tooth_Gum_Massager_Design_10_qdlh4w.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857934/Tooth_Gum_Massager_Design_4_fr09hu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857932/Tooth_Gum_Massager_Design_3_zgkx6f.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857931/Tooth_Gum_Massager_Design_2_hhqlmg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857929/Tooth_Gum_Massager_Design_1_m4xkcr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857927/Tooth_Gum_Massager_Design_8_xoetde.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857926/Tooth_Gum_Massager_Design_7_vywzkq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694857925/Tooth_Gum_Massager_Design_6_odjjqz.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858095/Trailer_Fabrication_Design_and_Draw_8_qlk8kv.jpg',
        title: "Trailer Fabrication Design and Drawings",
        para: "The 'Trailer Fabrication Design and Drawings' project encompassed the creation of comprehensive design plans and drawings for a specialized trailer. This trailer is tailored for specific transport needs, ensuring structural integrity, weight distribution, and safety compliance. The detailed drawings provided a roadmap for efficient fabrication, enabling the construction of a reliable and functional trailer for various industries and applications.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858095/Trailer_Fabrication_Design_and_Draw_8_qlk8kv.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858093/Trailer_Fabrication_Design_and_Draw_6_lvsiaj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858091/Trailer_Fabrication_Design_and_Draw_4_leiakh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858089/Trailer_Fabrication_Design_and_Draw_3_vvm58q.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858086/Trailer_Fabrication_Design_and_Draw_7_xyzckd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858084/Trailer_Fabrication_Design_and_Draw_15_oifwkt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858082/Trailer_Fabrication_Design_and_Draw_5_vd2jm3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858080/Trailer_Fabrication_Design_and_Draw_2_znm3de.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858078/Trailer_Fabrication_Design_and_Draw_1_zqyok3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858076/Trailer_Fabrication_Design_and_Draw_16_w2vimz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858074/Trailer_Fabrication_Design_and_Draw_9_bt72z6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858072/Trailer_Fabrication_Design_and_Draw_14_lp1z89.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858070/Trailer_Fabrication_Design_and_Draw_13_t4rdxg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858068/Trailer_Fabrication_Design_and_Draw_12_bctkws.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858066/Trailer_Fabrication_Design_and_Draw_10_klz7mz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858064/Trailer_Fabrication_Design_and_Draw_11_oejdn8.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858521/Wine_Bottle_CO2_Injector_and_Cap_Design_7_hjtefo.jpg',
        title: "Wine Bottle CO2 Injector and Cap Design",
        para: "The 'Wine Bottle CO2 Injector and Cap Design' project aimed to create a specialized cap for wine bottles that can inject controlled amounts of carbon dioxide to preserve wine freshness. This innovative design ensures the wine retains its flavor and quality after opening, catering to wine enthusiasts and the hospitality industry by extending the shelf life of opened bottles.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858531/Wine_Bottle_CO2_Injector_and_Cap_Design_2_dlazi6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858529/Wine_Bottle_CO2_Injector_and_Cap_Design_3_w9fxlt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858527/Wine_Bottle_CO2_Injector_and_Cap_Design_5_o4ubt2.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858525/Wine_Bottle_CO2_Injector_and_Cap_Design_4_drt1ar.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858523/Wine_Bottle_CO2_Injector_and_Cap_Design_1_xk220j.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858521/Wine_Bottle_CO2_Injector_and_Cap_Design_7_hjtefo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858519/Wine_Bottle_CO2_Injector_and_Cap_Design_6_m7yvit.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858518/Wine_Bottle_CO2_Injector_and_Cap_Design_8_jnmgwk.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858615/Drone_1_emtdny.jpg',
        title: "Folding wing drone design",
        para: "The 'Folding Wing Drone Design' project involved developing an innovative unmanned aerial vehicle with foldable wings. This design allows for efficient transportation and storage while maintaining excellent flight performance, making it ideal for applications such as reconnaissance, surveillance, and environmental monitoring in remote or challenging terrains.",
        type: 'Machine-ProductDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858614/Drone_3_xaezky.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858615/Drone_1_emtdny.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694858612/Drone_2_o3czod.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859645/AirBus-A380-CFD_2_mzj32h.jpg',
        title: "AirBus-A380 CFD Simulation",
        para: "The 'Airbus A380 CFD Simulation' project involved conducting Computational Fluid Dynamics (CFD) simulations to analyze the aerodynamic performance of the Airbus A380 aircraft. This extensive analysis aimed to understand airflow patterns, pressure distributions, and drag characteristics across various flight conditions. By using CFD simulations, engineers could optimize the aircraft's design, reduce fuel consumption, enhance flight stability, and improve overall performance, contributing to the efficiency and safety of this iconic commercial aircraft.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859645/AirBus-A380-CFD_2_mzj32h.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859643/AirBus-A380-CFD_1_jucvuw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859640/AirBus-A380-CFD_3_txh81y.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859638/AirBus-A380-CFD_7_l6demt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859636/AirBus-A380-CFD_5_lffaop.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859634/AirBus-A380-CFD_6_uihjin.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694859633/AirBus-A380-CFD_4_terqkm.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860197/Heat-Exchanger-CFD-Analysis-thumbnail_cbphbz.jpg',
        title: "Heat Exchanger CFD Analysis",
        para: "The 'Heat Exchanger CFD Analysis' project involves using Computational Fluid Dynamics(CFD) to simulate and analyze the thermal performance of a heat exchanger.This analysis helps optimize the design by studying the heat transfer, fluid flow patterns, and temperature distribution within the heat exchanger.By conducting CFD simulations, engineers can make informed decisions to improve efficiency, reduce energy consumption, and enhance the overall performance of heat exchangers in various applications, from HVAC systems to industrial processes.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860061/Heat_Exchanger_CFD_Analysis_4_hkp9zj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860058/Heat_Exchanger_CFD_Analysis_5_jvqxbr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860056/Heat_Exchanger_CFD_Analysis_3_hh5qve.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860054/Heat_Exchanger_CFD_Analysis_6_mae4fd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860051/Heat_Exchanger_CFD_Analysis_2_vuv15h.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860049/Heat_Exchanger_CFD_Analysis_7_qf8eqg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860047/Heat_Exchanger_CFD_Analysis_1_li3rof.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860044/Heat_Exchanger_CFD_Analysis_8_al9f9r.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860043/Heat_Exchanger_CFD_Analysis_9_s3nxpo.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860421/Heat-Flow-Simulation-_CFD_-thumbnail_vznrc2.jpg',
        title: "Heat Flow Simulation (CFD)",
        para: "The 'Heat Flow Simulation (CFD)' project involved conducting detailed Computational Fluid Dynamics (CFD) simulations to analyze and model the heat transfer and fluid flow patterns within a specific system or component. This analysis was crucial for optimizing thermal performance, identifying hotspots, and ensuring efficient heat dissipation in various engineering applications, such as electronic cooling systems, HVAC design, or industrial processes.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860475/HeatFlowPipesFEA_8_pznkt6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860473/HeatFlowPipesFEA_6_qeczmc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860470/HeatFlowPipesFEA_5_qbjg2u.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860468/HeatFlowPipesCFD_1_wpxvfb.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860466/HeatFlowPipesFEA_3_krsqc8.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860463/HeatFlowPipesFEA_2_jyykxq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860461/HeatFlowPipesFEA_7_aeb6oe.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860458/HeatFlowPipesCFD_2_n3mm1f.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860456/HeatFlowPipesCFD_4_znsbzs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860453/HeatFlowPipesFEA_4_cecdso.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860451/HeatFlowPipesCFD_3_fajmdy.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860564/LED-bulb-Thermal-Simulation-CFD_-thumbnail_hrsppz.jpg',
        title: "LED bulb Thermal Simulation-CFD",
        para: "The 'LED Bulb Thermal Simulation (CFD)' project entailed performing Computational Fluid Dynamics (CFD) simulations to assess the thermal behavior of LED bulbs. By modeling heat transfer and airflow within the bulb, this analysis aimed to optimize the design, ensuring efficient heat dissipation and prolonging the lifespan of the LED components. This project was essential for enhancing the performance and reliability of LED lighting systems while maintaining energy efficiency.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860606/LEDbulbCFD_5_xrn2yw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860604/LEDbulbCFD_4_lq8zcx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860601/LEDbulbCFD_3_twq7sl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860598/LEDbulbCFD_2_rpzzu6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860596/4_tc6dtf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860593/LEDbulbCFD_1_sv8q2f.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860763/Oil-storage-Tank-FEA-thumbnail_wriujy.jpg',
        title: "Oil storage Tank FEA",
        para: "The 'Oil Storage Tank FEA' project involved Finite Element Analysis (FEA) simulations to evaluate the structural integrity and stress distribution within an oil storage tank. This analysis helped ensure the tank's ability to withstand various loads, including the weight of stored oil, temperature variations, and external forces. By performing FEA, engineers could assess potential weaknesses, improve design parameters, and enhance the safety and durability of oil storage tanks, preventing structural failures and environmental hazards.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860816/OilTankFEA_1_nmxtyh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860814/OilTankFEA_6_egfsck.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860811/OilTankFEA_5_izjooi.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860810/OilTankFEA_4_obwytl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860806/OilTankFEA_2_m98zny.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860804/OilTankFEA_3_jwjkyt.jpg'
                },


            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860860/Oil-Tank-Fryer-CFD-thumbnail_puaw1v.jpg',
        title: "Oil Tank Fryer CFD",
        para: "The 'Oil Tank Fryer CFD' project entailed conducting Computational Fluid Dynamics (CFD) simulations to analyze the thermal performance and oil flow patterns within a deep fryer system. This analysis was crucial for optimizing the frying process by understanding how heat is distributed and how oil circulates within the tank. By performing CFD simulations, engineers aimed to enhance cooking efficiency, improve food quality, and minimize energy consumption in commercial and industrial fryer systems.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860894/OilTankFryerCFD_3_oosj38.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860891/OilTankFryerCFD_2_ndkskw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860888/OilTankFryerCFD_1_tkddsd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860885/OilTankFryerCFD_6_qa5yhc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860883/OilTankFryerCFD_5_wbqcpp.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694860880/OilTankFryerCFD_4_bzhwzo.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861086/RAT-_RAM-AIR-TURBINE_-CFD-Simulation-thumbnail_o3wvu8.jpg',
        title: "RAT (RAM AIR TURBINE) CFD Simulation",
        para: "The 'RAT (Ram Air Turbine) CFD Simulation' project involved using Computational Fluid Dynamics (CFD) to model and analyze the aerodynamic performance of a Ram Air Turbine system. This analysis was critical for understanding airflow patterns, pressure distributions, and turbine efficiency during emergency deployment scenarios on aircraft. By conducting CFD simulations, engineers could optimize the design, ensuring effective power generation and system functionality to provide essential electrical power during aircraft emergencies.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861131/RAT_RAM_AIR_TURBINE_CFD_Simulation_6_a1hnwc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861128/RAT_RAM_AIR_TURBINE_CFD_Simulation_1_gszty2.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861125/RAT_RAM_AIR_TURBINE_CFD_Simulation_10_h9qhip.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861122/RAT_RAM_AIR_TURBINE_CFD_Simulation_9_moif6f.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861120/RAT_RAM_AIR_TURBINE_CFD_Simulation_2_oqir1n.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861117/RAT_RAM_AIR_TURBINE_CFD_Simulation_7_tfpgum.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861114/RAT_RAM_AIR_TURBINE_CFD_Simulation_4_uaelbs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861111/RAT_RAM_AIR_TURBINE_CFD_Simulation_3_u6fllp.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861108/RAT_RAM_AIR_TURBINE_CFD_Simulation_8_qjvmgy.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861105/RAT_RAM_AIR_TURBINE_CFD_Simulation_5_riepjd.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861265/Shelter-FEA-Solidworks-thumbnail_ohyqly.jpg',
        title: "Shelter FEA Solidworks",
        para: "The 'Shelter FEA Solidworks' project focused on performing Finite Element Analysis (FEA) simulations using SOLIDWORKS to assess the structural integrity and strength of a shelter design. This analysis aimed to evaluate the shelter's ability to withstand various environmental factors, including wind loads, snow loads, and seismic forces. By conducting FEA simulations, engineers could identify potential weak points, optimize the design, and ensure the shelter's stability and safety in different conditions, making it suitable for its intended purpose, whether it's for housing, disaster relief, or other applications.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861261/Shelter-FEA_2_s6cimk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861258/Shelter-FEA_1_mzwksx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861255/Shelter-FEA_6_hej8s9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861252/Shelter-FEA_4_eedo3h.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861249/Shelter-FEA_3_iv6ggn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861247/Shelter-FEA_5_otmjtn.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861392/Shift-Fork-FEM-Abaqus-thumbnail_vru2zh.jpg',
        title: "Shift Fork FEM Abaqus",
        para: "The 'Shift Fork Finite Element Analysis (FEA) using Abaqus' project involved utilizing Abaqus, a powerful FEA software, to analyze the mechanical behavior and structural integrity of a shift fork component. This analysis was critical for assessing factors such as stress distribution, deformation, and fatigue life in response to varying loads and operational conditions. By conducting FEA simulations, engineers could optimize the design, material selection, and manufacturing process of the shift fork to ensure reliable and efficient performance in manual transmissions or similar mechanical systems.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861385/Frequency_Mode_6_gtcjs8.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861382/Frequency_Mode_5_cmbnkn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861378/Frequency_Mode_4_ci7hnp.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861374/Frequency_Mode_2_nq1vcz.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861374/Frequency_Mode_3_vjukrj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861370/SHIFT-FORK-FEM_2_y7k4eu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861368/Frequency_Mode_1_vfvhtq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861362/Frequency_Mode_10_uoagoh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861359/Frequency_Mode_8_qpfc9e.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861356/Frequency_Mode_7_drxaqo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861353/Frequency_Mode_9_oz0yki.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861352/SHIFT-FORK-FEM_1_j29o38.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861510/Silo-FEA-Simulation-thumbnail_ijbtml.jpg',
        title: "Silo FEA Simulation",
        para: "The 'Silo FEA Simulation' project entailed performing Finite Element Analysis (FEA) simulations to evaluate the structural integrity and stress distribution within a silo structure used for storing bulk materials such as grains, cement, or chemicals. This analysis was crucial for ensuring the silo's ability to withstand the weight of stored materials, environmental loads, and other stress factors. By conducting FEA simulations, engineers could identify potential weaknesses, optimize design parameters, and enhance the safety and durability of silo structures, preventing structural failures and material loss.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861504/Silo-FEA_1_duxhzh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861500/Silo-FEA_3_q2c0qp.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861497/Silo-FEA_4_fggvyi.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861494/Silo-FEA_2_yvmbqj.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861633/Solar-Panel-Flow-Analysis-thumbnail_kqe94x.jpg',
        title: "Solar Panel Flow Analysis",
        para: "The 'Solar Panel Flow Analysis' project involved conducting Computational Fluid Dynamics (CFD) simulations to study the airflow and heat transfer characteristics around solar panels. This analysis aimed to optimize the placement and orientation of solar panels in order to enhance their cooling and energy efficiency. By modeling the flow of air over the solar panels and assessing temperature distributions, engineers could improve the overall performance of solar installations, increase energy yield, and prolong the lifespan of photovoltaic systems, especially in hot and sunny climates.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861627/Solar-Panel-Flow-Analysis_2_khbmab.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861624/Solar-Panel-Flow-Analysis_14_a1ig6t.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861621/Solar-Panel-Flow-Analysis_5_wh7f2k.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861618/Solar-Panel-Flow-Analysis_7_juoapx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861615/Solar-Panel-Flow-Analysis_6_pvauy2.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861611/Solar-Panel-Flow-Analysis_1_qtkldr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861608/Solar-Panel-Flow-Analysis_3_bwrrey.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861605/Solar-Panel-Flow-Analysis_4_fiudl8.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861602/Solar-Panel-Flow-Analysis_16_wxp8qt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861599/Solar-Panel-Flow-Analysis_15_xfli5j.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861595/Solar-Panel-Flow-Analysis_12_gq7ub0.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861592/Solar-Panel-Flow-Analysis_13_egz8me.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861589/Solar-Panel-Flow-Analysis_11_pxib37.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861586/Solar-Panel-Flow-Analysis_10_qecsxc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861583/Solar-Panel-Flow-Analysis_9_vyh6kh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861580/Solar-Panel-Flow-Analysis_8_xol2eo.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861586/Solar-Panel-Flow-Analysis_10_qecsxc.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861792/Thermal-Analysis-Using-Matlab-thumbnail_w4gypd.jpg',
        title: "Thermal Analysis Using Matlab",
        para: "The 'Solar Panel Flow Analysis' project involved conducting Computational Fluid Dynamics (CFD) simulations to study the airflow and heat transfer characteristics around solar panels. This analysis aimed to optimize the placement and orientation of solar panels in order to enhance their cooling and energy efficiency. By modeling the flow of air over the solar panels and assessing temperature distributions, engineers could improve the overall performance of solar installations, increase energy yield, and prolong the lifespan of photovoltaic systems, especially in hot and sunny climates.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861788/Thermal-Matlab_2_edqjck.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861785/Thermal-Matlab_1_oqdi8y.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861781/Thermal-Matlab_5_nj5yai.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861778/Thermal-Matlab_7_gzezzw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861775/Thermal-Matlab_8_esasfs.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861772/Thermal-Matlab_4_ogb2ww.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861769/Thermal-Matlab_3_uvoaox.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861767/Thermal-Matlab_6_q8mheb.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861941/Thermal-Simulation-of-electronic-enclosure-thumbnail_rq5h6w.jpg',
        title: "Thermal Simulation of electronic enclosure",
        para: "The 'Thermal Simulation of Electronic Enclosure' project involved conducting detailed Computational Fluid Dynamics (CFD) or Finite Element Analysis (FEA) simulations to assess and optimize the thermal performance of an electronic enclosure. This analysis aimed to ensure that electronic components within the enclosure operate within safe temperature limits by studying heat distribution, airflow patterns, and the effectiveness of cooling mechanisms. The project resulted in improved design parameters and enhanced thermal management, ensuring the reliability and longevity of the enclosed electronics.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861937/Thermal-Simulation_10_y14yj6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861934/Thermal-Simulation_9_ode1nr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861931/Thermal-Simulation_8_qsefv7.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861928/Thermal-Simulation_7_oycpls.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861927/Thermal-Simulation_6_ihvryc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861921/Thermal-Simulation_5_dn9rta.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861917/Thermal-Simulation_4_m8e06w.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861914/Thermal-Simulation_3_oi37wc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861910/Thermal-Simulation_2_egbrt4.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694861908/Thermal-Simulation_1_bixuaf.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862197/Turbine-CFD-thumbnail_ujrm7g.jpg',
        title: "Turbine CFD",
        para: "The 'Turbine CFD' project focused on utilizing Computational Fluid Dynamics (CFD) simulations to analyze the aerodynamic performance of a turbine. This analysis aimed to understand fluid flow patterns, pressure distributions, and efficiency in turbines, such as gas turbines, steam turbines, or wind turbines. By conducting CFD simulations, engineers could optimize turbine designs, enhance energy generation, and improve overall performance in various industrial and renewable energy applications.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862193/Turbine_CFD_3_tz8rn9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862189/Turbine_CFD_1_lwv24o.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862186/Turbine_CFD_2_vc79p9.jpg'
                },

            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862074/Thermal-Simulation-Power-Converter-thumbnail_jvsxrb.jpg',
        title: "Thermal Simulation Power Converter",
        para: "The 'Thermal Simulation of Power Converter' project involved performing detailed Computational Fluid Dynamics (CFD) or Finite Element Analysis (FEA) simulations to analyze and optimize the thermal behavior of a power converter system. This analysis focused on heat distribution, temperature gradients, and the effectiveness of cooling mechanisms to ensure that the power converter operates within safe temperature limits. The project aimed to improve the converter's design, heat management, and overall efficiency, ensuring reliable performance and preventing overheating issues in various applications.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862069/Thermal-Simulation-Heat-Flow_7_nzkeb7.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862068/Thermal-Simulation-Heat-Flow_13_izyxlg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862063/Thermal-Simulation-Heat-Flow_11_cbycmg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862059/Thermal-Simulation-Heat-Flow_12_a1mryw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862056/Thermal-Simulation-Heat-Flow_8_tqnlwm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862052/Thermal-Simulation-Heat-Flow_5_rkp1c1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862049/Thermal-Simulation-Heat-Flow_9_g48nnm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862046/Thermal-Simulation-Heat-Flow_6_mbh1rm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862043/Thermal-Simulation-Heat-Flow_10_qmgqbk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862039/Thermal-Simulation-Heat-Flow_2_h29nsj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862035/Thermal-Simulation-Heat-Flow_3_ylyt2t.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862032/Thermal-Simulation-Heat-Flow_4_ecji0s.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862029/Thermal-Simulation-Heat-Flow_1_dfy6ra.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862339/Valve-CFD-and-Magnetic-Simulation-thumbnail_gmcjfd.jpg',
        title: "Valve CFD and Magnetic Simulation",
        para: "The 'Valve CFD and Magnetic Simulation' project involved utilizing Computational Fluid Dynamics (CFD) and magnetic field simulations to assess the performance and behavior of a magnetic valve. This analysis aimed to understand how the valve's magnetic components, such as solenoids or magnets, interacted with fluid flow within the valve body. By combining CFD and magnetic simulations, engineers could optimize the valve design for efficient fluid control, ensuring reliable and precise operation in applications like fluid regulation, automation, or industrial processes.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862335/Valve_CFD_and_Magnetic_Simulation_1_su2507.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862331/Valve_CFD_and_Magnetic_Simulation_2_k8dhcj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862327/Valve_CFD_and_Magnetic_Simulation_7_mpbayk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862324/Valve_CFD_and_Magnetic_Simulation_6_d5ajer.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862320/Valve_CFD_and_Magnetic_Simulation_4_ikiss9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862317/Valve_CFD_and_Magnetic_Simulation_3_popmnk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694862315/Valve_CFD_and_Magnetic_Simulation_5_kxjpom.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863858/Wall-Climbing-Robot-FEA-thumbnail_fcdnlk.jpg',
        title: "Wall Climbing Robot FEA",
        para: "The 'Wall Climbing Robot FEA' project involved performing Finite Element Analysis (FEA) simulations to assess the structural integrity and mechanical behavior of a wall climbing robot. This analysis aimed to understand stress distribution, load-bearing capacity, and the robot's ability to adhere to vertical surfaces. By conducting FEA simulations, engineers could optimize the robot's design, material selection, and safety features to ensure its reliable performance in climbing and maneuvering on walls or vertical structures in various industrial and inspection applications.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863853/Wall_Climbing_Robot_FEA_5_tqer6o.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863850/Wall_Climbing_Robot_FEA_6_xtvoab.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863846/Wall_Climbing_Robot_FEA_2_qreta5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863843/Wall_Climbing_Robot_FEA_3_varu63.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863839/Wall_Climbing_Robot_FEA_4_egkdm0.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863837/Wall_Climbing_Robot_FEA_1_rnllzv.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864749/Concept-Development-Thumbnail_e6iiwu.jpg',
        title: "Smart Table Design concept",
        para: "I am thrilled to introduce our groundbreaking Smart Table Design concept. Blending sleek aesthetics with ingenious engineering, our vision redefines furniture for the digital age. Explore the future of interactive surfaces and multifunctional living with our concept development that seamlessly integrates technology and design, setting new standards for innovation in the world of furniture.",
        type: 'ConceptDevelopment', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864745/Concept-6_j2gqni.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864741/Concept-5_i5g9ad.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864737/Concept-3_gj9szq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864733/Concept-1_pp2ql5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864728/Concept-4_gkwvrn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864724/Concept-2_jumhdg.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864285/Electric-tooth-Brush-Electronics-Design-Thumbnail_l7pm7y.jpg',
        title: "Electronic and Circuit Design",
        para: "Elevate your online presence with cutting-edge electronics and circuit designs! Our expertly crafted circuits fuse innovation with functionality, powering the digital world one project at a time. Join us on a journey through the electrifying realm of technology, where creativity meets engineering excellence.",
        type: 'Electronics-CircuitDesign', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864314/the-brush-charging-dock_zfjwe9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864318/schematic-02.11.2019_xsyxfc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864392/PCB-Design_8_brijh6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864388/PCB-Design_10_mtgbum.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864384/PCB-Design_7_gwgjox.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864381/PCB-Design_1_dbhxvl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864377/PCB-Design_6_shazp1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864373/PCB-Design_9_ewbavt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864370/PCB_5-to-24V_zeiit1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864366/PCB-Design_3_ppbhfd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864362/PCB-Design_4_rlty1q.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864358/PCB-Design_5_nt0nar.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864355/brushed_PCB_ft5rx6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864351/PCB-Design_2_fh2a8z.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864559/SolarBatteryChargerDigram_sdpnbr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864555/wiring-dargram-pool-cover_l7rzfq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864551/circuit-block-diagram_n69diu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864551/circuit-block-diagram_n69diu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864547/Schematic_24-to-5V_oju8ws.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864544/SolarBatteryCharger-schematic_qphjek.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864541/Brused_wtlm4d.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864655/Circuit_adtb3r.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864652/ED1_yeqes4.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864649/ED2_ilumjm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864655/Circuit_adtb3r.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864903/Architectural-Rendering-Thumbnail_inm7di.jpg',
        title: "Wall Climbing Robot FEA",
        para: "The 'Wall Climbing Robot FEA' project involved performing Finite Element Analysis (FEA) simulations to assess the structural integrity and mechanical behavior of a wall climbing robot. This analysis aimed to understand stress distribution, load-bearing capacity, and the robot's ability to adhere to vertical surfaces. By conducting FEA simulations, engineers could optimize the robot's design, material selection, and safety features to ensure its reliable performance in climbing and maneuvering on walls or vertical structures in various industrial and inspection applications.",
        type: 'FEA-CFDAnalysis', datatype: 'images',
        same: 'AllProjects',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863853/Wall_Climbing_Robot_FEA_5_tqer6o.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863850/Wall_Climbing_Robot_FEA_6_xtvoab.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863846/Wall_Climbing_Robot_FEA_2_qreta5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863843/Wall_Climbing_Robot_FEA_3_varu63.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863839/Wall_Climbing_Robot_FEA_4_egkdm0.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694863837/Wall_Climbing_Robot_FEA_1_rnllzv.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864749/Concept-Development-Thumbnail_e6iiwu.jpg',
        title: "Smart Table Design concept",
        para: "I am thrilled to introduce our groundbreaking Smart Table Design concept. Blending sleek aesthetics with ingenious engineering, our vision redefines furniture for the digital age. Explore the future of interactive surfaces and multifunctional living with our concept development that seamlessly integrates technology and design, setting new standards for innovation in the world of furniture.",
        type: 'ConceptDevelopment',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864745/Concept-6_j2gqni.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864741/Concept-5_i5g9ad.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864737/Concept-3_gj9szq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864733/Concept-1_pp2ql5.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864728/Concept-4_gkwvrn.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864724/Concept-2_jumhdg.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864285/Electric-tooth-Brush-Electronics-Design-Thumbnail_l7pm7y.jpg',
        title: "Electronic and Circuit Design",
        para: "Elevate your online presence with cutting-edge electronics and circuit designs! Our expertly crafted circuits fuse innovation with functionality, powering the digital world one project at a time. Join us on a journey through the electrifying realm of technology, where creativity meets engineering excellence.",
        type: 'Electronics-CircuitDesign',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864314/the-brush-charging-dock_zfjwe9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864318/schematic-02.11.2019_xsyxfc.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864392/PCB-Design_8_brijh6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864388/PCB-Design_10_mtgbum.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864384/PCB-Design_7_gwgjox.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864381/PCB-Design_1_dbhxvl.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864377/PCB-Design_6_shazp1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864373/PCB-Design_9_ewbavt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864370/PCB_5-to-24V_zeiit1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864366/PCB-Design_3_ppbhfd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864362/PCB-Design_4_rlty1q.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864358/PCB-Design_5_nt0nar.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864355/brushed_PCB_ft5rx6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864351/PCB-Design_2_fh2a8z.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864559/SolarBatteryChargerDigram_sdpnbr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864555/wiring-dargram-pool-cover_l7rzfq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864551/circuit-block-diagram_n69diu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864551/circuit-block-diagram_n69diu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864547/Schematic_24-to-5V_oju8ws.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864544/SolarBatteryCharger-schematic_qphjek.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864541/Brused_wtlm4d.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864655/Circuit_adtb3r.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864652/ED1_yeqes4.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864649/ED2_ilumjm.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864655/Circuit_adtb3r.jpg'
                },



            ]

    },
    {
        image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865260/Architectural-Rendering-Thumbnail_p96mqv.jpg',
        title: "Architectural Renderings",
        para: "Elevate your online presence with cutting-edge electronics and circuit designs! Our expertly crafted circuits fuse innovation with functionality, powering the digital world one project at a time. Join us on a journey through the electrifying realm of technology, where creativity meets engineering excellence.",
        type: 'ArchitecturalWalkthrough',
        same: 'AllProjects', datatype: 'images',
        imageData:
            [
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865190/Architectural-Render-_20_vodzmw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865186/Architectural-Render-_24_a7eapk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865182/Architectural-Render-_23_vldser.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865178/Architectural-Render-_25_lpaucu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865174/Architectural-Render-_17_sluj44.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865169/Architectural-Render-_3_3_uwaju3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865165/Architectural-Render-_3_lwjy6c.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865161/Architectural-Render-_21_hz4d8v.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865157/Architectural-Render-_4_4_l58rzx.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865153/Architectural-Render-_8_obcphf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865149/Architectural-Render-_18_ceqzgj.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865145/Architectural-Render-_27_hwa51u.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865141/Architectural-Render-_16_luh3z1.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865137/Architectural-Render-_11_xdl6i6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865133/Architectural-Render-_22_enozys.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865129/Architectural-Render-_6_zqbwez.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865125/Architectural-Render-_26_gmmzn3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865121/Architectural-Render-_14_udwhrw.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865117/Architectural-Render-_19_owjwkk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865113/Architectural-Render-_15_necqcu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865109/Architectural-Render-_12_uh0dn7.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865105/Architectural-Render-_7_7_ob7xw3.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865101/Architectural-Render-_10_y7dmwf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865097/Architectural-Render-_9_arx3yr.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865093/Architectural-Render-_6_6_b7sdoy.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865089/Architectural-Render-_8_8_q4njzi.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865084/Architectural-Render-_4_aybfhd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865082/Architectural-Render-_13_mhplnd.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865076/Architectural-Render-_5_kvpecf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865075/Architectural-Render-_5_5_emyi6a.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865068/Architectural-Render-_37_qpdku9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865064/Architectural-Render-_7_bthiuf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865060/Architectural-Render-_34_sil5jt.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865056/Architectural-Render-_44_cxp8en.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865052/Architectural-Render-_35_nthwc4.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865048/Architectural-Render-_39_rmnh0v.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865044/Architectural-Render-_42_kho5wq.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865040/Architectural-Render-_41_rw8tya.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865036/Architectural-Render-_40_wb9kb6.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865032/Architectural-Render-_43_fz55g9.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865028/Architectural-Render-_38_v2ripu.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865024/Architectural-Render-_32_gn5lyg.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865020/Architectural-Render-_33_cxkpee.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865015/Architectural-Render-_1_jslneb.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865011/Architectural-Render-_2_2_jidr89.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865007/Architectural-Render-_1_1_gorthh.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694865003/Architectural-Render-_2_ekkotk.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864999/Architectural-Render-_30_dxh2ra.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864994/Architectural-Render-_28_nsy1fa.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864990/Architectural-Render-_31_kcrwjf.jpg'
                },
                {
                    mainUrl: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1694864988/Architectural-Render-_29_cgkbhh.jpg'
                },



            ]

    },



];
export default engineeringProjectImage_data;