import confiImage from '../../assests/img-design/concept-development.png'
const configuratordata = {
    serviceBar: [
        { href: '#virtualreality', title: 'VR-Virtual Reality' },
        { href: '#extendedreality', title: 'XR-Extended Reality' },
        { href: '#configurator', title: 'Configurator' },
    ],
    configurator: [
        {
            title: ' VR - Virtual Reality',
            videoId: 'Zddof7W_xnY',
            para: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex voluptas, omnis dicta nesciunt cupiditate dolorum! Debitis rerum sequi, natus quaerat autem ipsam, provident quam eum iusto, iste eaque explicabo deleniti. Cum maxime neque reiciendis ducimus libero molestias? Magnam odio tempore voluptates in quasi aliquam illum maxime quos eos recusandae quam explicabo ducimus sit illo, rem dolorum maiores aspernatur voluptatem enim cumque repudiandae! Sequi sit dolore deleniti impedit modi expedita, dicta, exercitationem, ullam voluptates blanditiis molestias maiores dolor unde cupiditate. Dolores incidunt porro adipisci obcaecati odio ducimus maxime inventore qui laboriosam nam, est voluptatibus beatae error vel quos quod deserunt itaque et quae reprehenderit odit impedit tempore! Ea, perspiciatis inventore fugit corrupti libero eius optio, quas praesentium non quae delectus adipisci quasi repellendus. Alias ex vero, quibusdam, facilis consequuntur corrupti quisquam vitae repellat distinctio rerum reiciendis expedita ipsam reprehenderit laborum placeat sit vel, et omnis! Doloribus excepturi autem commodi libero?',


        }
    ],
    VR_DEMOS: [
        {
            configuratorTitle: 'Toaster',
            configuratorImage: confiImage,
            configuratorLink: 'http://toaster.configurator.vutuk.com/',
        }
    ]
}


export default configuratordata;