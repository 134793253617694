
// DATA IMPORT 
import engineeringProjectData from './engineeringProject_data'
import engineeringProjectImage_data from './engineeringProjectImage_data'

// MACHINE AND PRODUCT DESIGN
const machineAndProductDesign = engineeringProjectImage_data
    .filter(el => {
        if (
            el.type.split(' ').find(element =>
                element === 'Machine-ProductDesign')) {
            return el;
        } else {
            return null;
        }
    })

// Shuffle the array randomly
for (let i = machineAndProductDesign.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [machineAndProductDesign[i], machineAndProductDesign[j]] = [machineAndProductDesign[j], machineAndProductDesign[i]];
}

// Get the first 5 elements
const machineAndProductDesignRandom = machineAndProductDesign.slice(0, 3);
////////////////////////////////////////
// CFD AND FEA
const cfdAndFea = engineeringProjectImage_data.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'FEA-CFDAnalysis')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = cfdAndFea.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [cfdAndFea[i], cfdAndFea[j]] = [cfdAndFea[j], cfdAndFea[i]];
}

// Get the first 5 elements
const cfdAndFeaRandom = cfdAndFea.slice(0, 3);
////////////////////////////////////////
// 3D ANIMATION
const conceptDeveloment = engineeringProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'ConceptDevelopment')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = conceptDeveloment.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [conceptDeveloment[i], conceptDeveloment[j]] = [conceptDeveloment[j], conceptDeveloment[i]];
}

// Get the first 5 elements
const conceptDevelomentRandom = conceptDeveloment.slice(0, 3);
////////////////////////////////////////
// Graphic Designing
const productRendering = engineeringProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'ProductRendering')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = productRendering.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [productRendering[i], productRendering[j]] = [productRendering[j], productRendering[i]];
}

// Get the first 5 elements
const productRenderingRandom = productRendering.slice(0, 3);

////////////////////////////////////////
// Video Production
const animation3D = engineeringProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === '3DAnimations')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = animation3D.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [animation3D[i], animation3D[j]] = [animation3D[j], animation3D[i]];
}

// Get the first 5 elements
const animation3DRandom = animation3D.slice(0, 3);



const serviceDesign = {
    servicesSection: [
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021445/machine-design_yg5zyh.png',
            title: 'Machine & Prodcut Design',
            description: 'At Vutuk, we specialize in turning your innovative ideas into reality. Our experienced team of engineers and designers collaborates closely with you to create customized, cost-effective designs, utilizing cutting-edge technology. From concept to development, we streamline the entire process, ensuring efficient and high-quality results. With a client-centric approach and a proven track record across various industries, Vutuk is your trusted partner in bringing your vision to life. Contact us today to explore how our Machine & Product Design service can propel your projects forward.',
            id: 'machineandproductdesign',
            randomProjects: machineAndProductDesignRandom,
            type: 'videos'
        },





        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021458/concept-development_zzfqpb.png',
            title: 'Concept Development',
            description: "At Vutuk, we specialize in nurturing your product ideas from conception to reality. Our dedicated team of innovators and designers work closely with you to shape your vision into a well-defined product concept. We engage in thorough market research, brainstorming, and prototyping to ensure that your concept aligns with market demands and user needs. With a focus on creativity and feasibility, we transform abstract ideas into actionable plans. Whether you're launching a new product or revitalizing an existing one, trust Vutuk to bring fresh, market-ready concepts to your brand. Contact us today to start the journey from idea to innovation.",
            id: 'conceptDevelopment',
            randomProjects: conceptDevelomentRandom,
            type: 'videos'
        },


        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021476/product-rendering_wvqiqt.png',
            title: 'Product Rendering',
            description: "At Vutuk, we bring your product visions to life through stunning and realistic 3D product rendering. Our talented team of artists and designers utilizes advanced rendering techniques to create visually captivating representations of your products. Whether you're in the early stages of concept development or looking to showcase a finished product, our high-quality renderings provide a powerful visual tool for marketing, prototyping, and presentations. Elevate your brand and captivate your audience with lifelike product imagery that leaves a lasting impression. Contact us today to see how Vutuk can transform your product ideas into captivating visual experiences.",
            id: 'productRendering',
            randomProjects: productRenderingRandom,
            isClass: 'new2',
            type: 'videos'
        },



        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021604/3d-animation_zfffrc.png',
            title: '3D Animations',
            description: "At Vutuk, we breathe life into your concepts and products through captivating 3D animations. Our skilled team of animators and visual artists employs cutting-edge technology to create immersive and dynamic animations that engage, inform, and inspire. Whether you need product demonstrations, architectural walkthroughs, or intricate visual storytelling, our 3D animations convey your message with clarity and impact. We bring your ideas to life, allowing your audience to experience them in a vivid and interactive manner. Partner with Vutuk to transform your visions into unforgettable animated experiences. Contact us today to explore the world of possibilities that 3D animations can unlock for your projects.",
            id: '3dAnimations',
            randomProjects: animation3DRandom,
            type: 'videos'
        },
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021646/configurator_vfm94b.png',
            title: 'Product Configurator',
            description: "Vutuk offers a powerful and customizable configurator solution that can serve as a standalone product for your business. Our Configurator as a Product empowers your customers to personalize and tailor products to their unique preferences and requirements with ease. Whether you're in the manufacturing, e-commerce, or service industry, our versatile configurator can be seamlessly integrated into your website or software, offering an immersive and user-friendly experience. With features such as real-time visualization, pricing, and compatibility checks, our configurator simplifies complex decision-making and enhances customer engagement. Stay ahead of the competition and provide your customers with the ultimate customization experience with Vutuk's Configurator as a Product. Contact us today to explore how our solution can transform your business and drive customer satisfaction to new heights.",
            randomProjects: animation3DRandom, isClass: 'new2',
            type: 'videos'
        },
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021677/FEA-_-CFD-Analysis_kztbob.png',
            title: 'FEA & CFD Analysis',
            description: "At Vutuk, we offer cutting-edge Computational Fluid Dynamics (CFD) and Finite Element Analysis (FEA) services to empower your engineering projects. With our state-of-the-art simulations and analysis tools, we provide in-depth insights into the behavior of your designs, whether it's assessing fluid flow, heat transfer, structural integrity, or other complex phenomena. Our experienced engineers and analysts employ advanced software and methodologies to optimize your designs, reduce risks, and enhance performance. Trust Vutuk for precise, data-driven solutions that ensure your products and systems are robust, efficient, and reliable. Contact us today to leverage the power of CFD and FEA analysis in your projects.",
            id: 'cfdandfeaanalysis',
            randomProjects: cfdAndFeaRandom,
            isClass: 'new2',
            type: 'images'
        },
    ],
    servicebar: [
        { href: '#machineandproductdesign', service: ' Machine & Prodcut Design' },

        { href: '#conceptDevelopment', service: ' Concept Development' },
        { href: '#productRendering', service: ' Product Rendering' },
        { href: '#3dAnimations', service: ' 3D Animations' },
        { href: '#cfdandfeaanalysis', service: ' FEA & CFD Analysis' },
    ],

    randomProjects: [machineAndProductDesignRandom, cfdAndFeaRandom, conceptDevelomentRandom, productRenderingRandom, animation3DRandom]

}

export default serviceDesign;