import ImageIfranShekh from '../../assests/team-img/irfan-shekh.png'
import ImageAshrafShekh from '../../assests/team-img/ashraf-shekh.png'
import ImageISahilKhan from '../../assests/team-img/sahil-khan.png'
import ImageAyushDharu from '../../assests/team-img/ayush-dharu.png'
const engineeringProjectData = [
    {
        id: 1,
        image: ImageIfranShekh,
        name: 'IRFAN SHEKH',
        profession: 'CEO and Co-Founder'
    },
    {
        id: 2,
        image: ImageAshrafShekh,
        name: 'ASHRAF SHEKH',
        profession: 'CFO and Co-Founder'
    },
    {
        id: 3,
        image: ImageAyushDharu,
        name: 'AYUSH DHARU',
        profession: 'Project Manager'
    },
    {
        id: 4,
        image: ImageISahilKhan,
        name: 'SAHIL KHAN',
        profession: 'Website Manager'
    },


]

export default engineeringProjectData;