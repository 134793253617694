import { useInView } from 'react-intersection-observer';
import React from 'react'
import { useEffect, useState } from 'react'
import NavBar from '../navbar/Navbar'
import DesignLogo from '../../assests/logo/Vutuk-Logo_design.png'
import { Link } from 'react-router-dom'
import Footer from '../footer/Footer'
import CTA from '../cta/CTA'
import SwitchLogoMedia from '../../assests/logo/SwitchMedia.png'
import '../../extra/Servicesnew.css'
import YouTubeEmbed from '../embedVideo/YouTubeEmbed';

import configuratordata from '../data/configurator';


import './Configurator.css'
// import SayduckViewer from '../../EmbedViewer/SayduckViewer';

const Configurator = (props) => {
    const [navbarHeight, setNavbarHeight] = useState(0);

    useEffect(() => {
        // Function to calculate and set the navbar height dynamically
        const calculateNavbarHeight = () => {
            const navbar = document.getElementById('navbar'); // Replace 'navbar' with the actual ID of your navbar
            if (navbar) {
                const height = navbar.offsetHeight;
                setNavbarHeight(height);
            }
        };

        // Call the calculateNavbarHeight function when the component mounts and on window resize
        calculateNavbarHeight();
        window.addEventListener('resize', calculateNavbarHeight);

    })
    const [ref, inView] = useInView({
        triggerOnce: false, // Only trigger once when the element enters the viewport
        threshold: 0.1,   // Trigger when at least 50% of the element is visible
    });


    const containerStyles = {
        // Add other styles as needed
        position: inView ? 'fixed' : 'absolute',
        top: inView ? `${navbarHeight}px` : '0',
    };

    return (
        <div>

            <section>
                <NavBar logo={DesignLogo} />


                <div className="servicesbar confi-bar" style={containerStyles}>

                    <div className='servicebar'>
                        {configuratordata.serviceBar.map((el, i) => (
                            <a href={el.href} key={i} className='servicebar-list'>
                                {el.title}
                            </a>
                        ))}

                    </div>
                </div>
                {configuratordata.configurator.map((el, i) => (


                    <div key={i} className="configurator-title" id={el.id}>
                        <h1>
                            {el.title}
                        </h1>
                        <div className="underline"></div>

                        <p>What is {el.title} ?</p>
                        <div className='configurator-video'>
                            <YouTubeEmbed videoId={el.videoId} width="560" height="315" className='configurator-video-components' />
                            <p className='configurator-video-components'>{el.para}</p>
                        </div>


                    </div>




                ))}


                {configuratordata.VR_DEMOS.map((el, i) => (
                    <div className="configurator--service-container" ref={ref} key={i}>
                        <a href={el.configuratorLink} target='_blank' rel='noreferrer'>
                            <div className="configurator-container">
                                <div className="configurator-details">
                                    <h2>{el.configuratorTitle}</h2>
                                </div>
                                <div className="configurator-image">

                                    <img src={el.configuratorImage} alt="" />
                                </div>
                            </div>

                        </a>

                    </div>
                ))}

                <div>
                    <iframe width={800} height={500} src="https://savree.com/en/3d-interface-builder/embed/7824aba57765e1cc" title='viewer' frameborder="0"></iframe>
                </div>



                <Link to='/media'>
                    <CTA logoImage={SwitchLogoMedia} />

                </Link>


            </section >
            <Footer
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'} />



        </div>
    )
}

export default Configurator