
const engineeringProjectData = [
    {
        id: 1,
        title: "Revolutionary Floor Sifter: A 3D Animation by Vutuk Design",
        para: "Discover the future of food industry innovation with Vutuk Design and Media's revolutionary Floor Shifter! 🍳🔧 Witness the cutting-edge technology and precision engineering that has redefined sifting in the culinary world through our captivating #3DAnimation.",
        videoLink: "mEUBcKHR1Es",
        type: '3DAnimations Machine-ProductDesign ProductRendering ConceptDevelopment',
        same: 'AllProjects'
    },
    {
        id: 2,
        title: "Renuvi Touch: Vutuk Design's 3D Animated Face and Neck Massage Innovation",
        para: "Indulge in the ultimate relaxation experience with Renuvi Touch, Vutuk Design and Media's revolutionary creation! 🌟🤗 Witness the mesmerizing 3D animation of our ingeniously designed face and neck massage device, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "hGXU3Q99JPs",
        type: '3DAnimations Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 3,
        title: "Revolutionary 3D Printing: Vutuk Design's Animated 3D Printer Unveiled!",
        para: "Step into the future of additive manufacturing with Vutuk Design and Media's groundbreaking 3D Printer! 🚀🖨️ Witness the next evolution in 3D printing through our mesmerizing #3DAnimation, showcased exclusively on the 'Vutuk Design' YouTube channel.",
        videoLink: "iT52AZPtvHE",
        type: '3DAnimations Machine-ProductDesign ConceptDevelopment',
        same: 'AllProjects'
    },


    {
        id: 5,
        title: "BreathePure: Vutuk Design's Animated Air Purifier for a Healthier Home!",
        para: "Experience the pinnacle of air purification technology with Vutuk Design and Media's groundbreaking creation - BreathePure! 🌬️💨 Our animated 3D air purifier is set to revolutionize the Home Appliances industry, ensuring you and your loved ones breathe cleaner, fresher air.",
        videoLink: "iX_Y-Y3xSX8",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },


    {
        id: 7,
        title: "SecureSnap: Vutuk Design's 3D Animated SD Card Holder for Ultimate Memory Storage!",
        para: "Discover the epitome of memory card organization with Vutuk Design and Media's ingenious creation - SecureSnap! 📷🎥 Our animated 3D SD card holder is revolutionizing the Memory Card industry, providing a sleek and secure solution to keep your precious memories safe and easily accessible.",
        videoLink: "FtZhdgud6Yk",
        type: '3DAnimations ConceptDevelopment',
        same: 'AllProjects'
    },


    {
        id: 12,
        title: "SkyEagle: Vutuk Design's Futuristic Jet Drone Concept - 3D Animation",
        para: "Embark on an exhilarating journey through the skies with Vutuk Design and Media's groundbreaking creation - SkyEagle! 🚀🦅 Our animated 3D jet drone concept is set to redefine aerial exploration, pushing the boundaries of design and technology.",
        videoLink: "eDmO0TORXHw",
        type: '3DAnimations ConceptDevelopment',
        same: 'AllProjects'
    },
    {
        id: 13,
        title: "CoolOnTheGo: Mini Portable Coke Drink Cooler - 3D Animation",
        para: "Introducing CoolOnTheGo, the ultimate #PortableCokeCooler designed by Vutuk Design and Media! 🥤🔋 Experience the future of refreshment with this innovative battery-operated mini cooler, brought to life through stunning #3DAnimation.",
        videoLink: "SWyEiGcDnYE",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 14,
        title: "Mythical Realms: Vutuk Design's 3D Dragon Toy - Explode and Assemble Animation",
        para: "Enter the enchanting world of Mythical Realms with Vutuk Design and Media's mesmerizing creation - the 3D Dragon Toy! 🐉🧚‍♂️ Our captivating explode and assemble animation takes you on an extraordinary journey, revealing the intricate details and magic behind this mythical masterpiece.",
        videoLink: "PMsNEc1Lrn4",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 15,
        title: "RoboJaws: Vutuk Design's Robotic Jaw Mechanism - 3D Animation",
        para: "Welcome to the future of robotics with RoboJaws, Vutuk Design and Media's ingenious creation! 🤖🦷 Witness the mesmerizing 3D animation of our groundbreaking robotic jaw mechanism for the head of humanoid robots, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "fze79g29Ojo",
        type: '3DAnimations ConceptDevelopment',
        same: 'AllProjects'
    },
    {
        id: 16,
        title: "CycleFit: Vutuk Design's Innovative Stationary Cycle Exercise Machine - 3D Animation",
        para: "Welcome to the world of fitness innovation with CycleFit, Vutuk Design and Media's exceptional creation! 🚴‍♂️💨 Witness the dynamic 3D animation of our cutting-edge stationary cycle exercise machine, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "hlSwcAwWfcQ",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 17,
        title: "ElevateTech: Vutuk Design's 3D Animated Gear Box for Elevators",
        para: "Step into the future of elevator technology with ElevateTech, Vutuk Design and Media's ingenious creation! 🏢🔧 Witness the mesmerizing 3D animation of our groundbreaking gear box, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "S8veNwJzrwo",
        type: '3DAnimations Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 18,
        title: "MixMaster: Vutuk Design's 3D Animated Horizontal Mixer for the Food Industry",
        para: "Discover the art of culinary excellence with MixMaster, Vutuk Design and Media's innovative creation! 🍳🥘 Witness the mesmerizing 3D animation of our cutting-edge horizontal mixer, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "uYD-hfj7MVU",
        type: '3DAnimations Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 19,
        title: "GearFlow: Vutuk Design's 3D Animated Internal Gear Pump Assembly",
        para: "Explore the engineering marvel of GearFlow, Vutuk Design and Media's innovative creation! 🚀🔩 Witness the mesmerizing 3D animation of our meticulously designed internal gear pump assembly, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "fJS5e81w14w",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 20,
        title: "DemoWise: Vutuk Design's 3D Animated Mechanism for Engaging Demonstrations",
        para: "Experience the brilliance of DemoWise, Vutuk Design and Media's ingenious creation! 🌟🔧 Witness the captivating 3D animation of our thoughtfully designed mechanism for engaging demonstrations, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "XZdkYtISbhs",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },
    {
        id: 21,
        title: "Revolutionary 3D Printing: Vutuk Design's Animated 3D Printer Unveiled!",
        para: "Step into the future of additive manufacturing with Vutuk Design and Media's groundbreaking 3D Printer! 🚀🖨️ Witness the next evolution in 3D printing through our mesmerizing #3DAnimation, showcased exclusively on the 'Vutuk Design' YouTube channel.",
        videoLink: "FiN45Xk0zho",
        type: '3DAnimations ConceptDevelopment Machine-ProductDesign',
        same: 'AllProjects'
    },


    {
        id: 23,
        title: "TurboBoost: Vutuk Design's 3D Animated Automobile Turbo Innovation",
        para: "Unleash the power of TurboBoost, Vutuk Design and Media's engineering marvel! 🚗💨 Witness the mesmerizing 3D animation of our precision-designed automobile turbo, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "SdxbWJhR428",
        type: '3DAnimations ConceptDevelopment',
        same: 'AllProjects'
    },
    {
        id: 24,
        title: "VineraCO2: Vutuk Design's 3D Animated Wine Preservation Innovation",
        para: "Experience the art of wine preservation with VineraCO2, Vutuk Design and Media's ingenious creation! 🍷🌬️ Witness the captivating 3D animation of our precision-engineered device, exclusively on our YouTube channel 'Vutuk Design.'",
        videoLink: "JKpf3mpN2ls",
        type: '3DAnimations Machine-ProductDesign ConceptDevelopment',
        same: 'AllProjects'
    },


]

export default engineeringProjectData;