
import mediaProjectData from './mediaProject_data'



// VIDEO EDITING
const videoEditing = mediaProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'VideoEditing')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = videoEditing.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [videoEditing[i], videoEditing[j]] = [videoEditing[j], videoEditing[i]];
}

// Get the first 5 elements
const videoEditingRandom = videoEditing.slice(0, 3);
////////////////////////////////////////
// 2D ANIMATION
const animation2D = mediaProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === '2DAnimation')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = animation2D.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [animation2D[i], animation2D[j]] = [animation2D[j], animation2D[i]];
}

// Get the first 5 elements
const animation2DRandom = animation2D.slice(0, 3);
////////////////////////////////////////
// 3D ANIMATION
const animation3D = mediaProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === '3DAnimation')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = animation3D.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [animation3D[i], animation3D[j]] = [animation3D[j], animation3D[i]];
}

// Get the first 5 elements
const animation3DRandom = animation3D.slice(0, 3);
////////////////////////////////////////
// Graphic Designing
const graphicDesigning = mediaProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'GraphicDesigning')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = graphicDesigning.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [graphicDesigning[i], graphicDesigning[j]] = [graphicDesigning[j], graphicDesigning[i]];
}

// Get the first 5 elements
const graphicDesigningRandom = graphicDesigning.slice(0, 3);

////////////////////////////////////////
// Video Production
const videoProduction = mediaProjectData.filter(el => {
    if (
        el.type.split(' ').find(element =>
            element === 'VideoProduction')) {
        return el;
    } else {
        return null;
    }
})

// Shuffle the array randomly
for (let i = videoProduction.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [videoProduction[i], videoProduction[j]] = [videoProduction[j], videoProduction[i]];
}

// Get the first 5 elements
const videoProductionRandom = videoProduction.slice(0, 3);


const serviceMedia = {
    servicesSection: [
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021714/Video-Editing-services_bqty9m.png',
            title: 'Video Editing',
            description: 'Our Video Editing Service transforms raw footage into captivating visual stories. Our skilled editors enhance colors, merge clips, and add effects, creating professional-quality videos that engage your audience. Whether for personal memories, business promotions, or creative projects, we tailor our editing to your vision, meeting your deadlines with precision. Elevate your videos with us, where your vision meets expertise.',
            id: 'video-editing',
            randomProjects: videoEditingRandom,
        },


        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021726/2d-animation-services_njhgjk.png',
            title: '2D Animation',
            description: 'Our 2D Animation Services bring your ideas to life with creativity and precision. Whether for entertainment, education, or marketing, our talented animators craft captivating animations tailored to your vision. We blend artistry and technology to create engaging animations that resonate with your audience. Elevate your projects with us, where creativity meets expertise, delivering animations that captivate and communicate effectively.',
            id: '2d-aniamtion',
            randomProjects: animation2DRandom,
            isClass: 'new2',
        },


        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021739/3d-animation-services_itnrra.png',
            title: '3D Animation',
            description: 'Our 3D Animation Services bring your ideas to life with creative precision. Ideal for entertainment, education, or marketing, our skilled animators craft captivating 3D animations. Fusing artistry and technology, we create immersive visuals that engage your audience. Elevate your projects with us, where creativity meets expertise, delivering captivating 3D animations for effective digital communication.',
            id: '3d-aniamtion',
            randomProjects: animation3DRandom,
        },


        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021751/Graphic-design-services_fhzg5t.png',
            title: 'Graphic Designing',
            description: 'Our Graphic Design services transform your concepts into stunning visuals with creative precision. Whether for branding, marketing materials, or digital assets, our talented designers craft captivating graphics that align with your vision. From striking logos to eye-catching layouts, we blend creativity and expertise to create designs that leave a lasting impression. Elevate your projects with our Graphic Design services, where creativity meets skill, delivering visuals that communicate your message effectively.',
            id: 'graphic-designing',
            randomProjects: graphicDesigningRandom,
            isClass: 'new2',
        },



        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021769/Video-Production-services_rn9fyh.png',
            title: 'Video Production',
            description: 'Our Video Production services bring your vision to life with expertise and creativity. Whether for promotions, documentaries, or corporate content, we handle everything from scripting to post-production. Combining technical prowess and creative flair, we deliver captivating videos that engage your audience. Elevate your projects with us, where creativity meets precision in Video Production, producing content that inspires and resonates.',
            id: 'video-production',
            randomProjects: videoProductionRandom
        },
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021784/vfx_v12mch.png',
            title: 'Visual Effects',
            description: "Vutuk is your gateway to the world of enchanting visual effects that transcend reality. Our team of skilled VFX artists and technicians harness the power of technology to seamlessly blend the virtual with the real. Whether you're looking to enhance your films, commercials, or promotional content, our VFX services add that touch of magic and realism that captivates audiences.From breathtaking CGI to stunning compositing, we create immersive visual experiences that leave a lasting impression.Elevate your visual storytelling and transform ordinary scenes into extraordinary moments with Vutuk's VFX expertise. Contact us today to explore how our VFX services can make your projects come to life in ways you never thought possible.",
            id: 'visual-effects',
            randomProjects: videoProductionRandom,
            isClass: 'new2',
        },
        {
            image: 'https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021646/configurator_vfm94b.png',
            title: 'Product Configurator',
            description: "Vutuk offers a powerful Configurator as a Product solution, perfect for businesses in various industries. Our versatile configurator integrates easily into your website or software, allowing customers to personalize and customize products effortlessly. With features like real-time visualization, pricing, and compatibility checks, it simplifies complex decision-making and boosts customer engagement. Elevate your business and provide the ultimate customization experience with Vutuk's Configurator as a Product. Contact us to learn how it can transform your business and enhance customer satisfaction.",
            randomProjects: videoProductionRandom,
        },
    ],
    servicebar: [
        { href: '#video-editing', service: 'Video Editing' },
        { href: '#2d-aniamtion', service: '2D Animation' },
        { href: '#3d-aniamtion', service: '3D Animation' },
        { href: '#graphic-designing', service: 'Graphic Designing' },
        { href: '#video-production', service: 'Video Production' },
    ],

    randomProjects: [videoEditingRandom, animation2DRandom, animation3DRandom, graphicDesigningRandom, videoProductionRandom]

}

export default serviceMedia;