import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Main from '../../components/main/Main'
import SwitchLogoMedia from '../../assests/logo/SwitchMedia.png'


import EngineeringPageMain from './EngineeringPageMain'

import Footer from '../../components/footer/Footer'

import engineeringLogo from '../../assests/logo/Vutuk-Logo_design.png'
import CTA from '../../components/cta/CTA'

import { Link } from 'react-router-dom'
import Clients from '../../components/clients/Clients'
import serviceDesign from '../../components/data/serviceDesign'
import Servicesnew from '../../extra/Servicesnew'


const EngineeringPage = () => {
    return (
        <>
            <Navbar
                navlogo={'/design'}
                logo={engineeringLogo}
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'} />
            <Main
                mainTitle={EngineeringPageMain.mainTitle} mainPara={EngineeringPageMain.mainPara}
                mainImage={EngineeringPageMain.mainImage}
                typeWriter={EngineeringPageMain.typeWriter}
                redirectContact={'/design/contact'}
                mainVideoId={'fY2UpKQz2Kc'}
            />

            <Servicesnew
                data={serviceDesign}
            />





            <Clients />
            {/* <EngineeringTestimonialsPage /> */}
            <Footer
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'}

            />
            <Link to='/media'>
                <CTA logoImage={SwitchLogoMedia} />

            </Link>

        </>
    )
}

export default EngineeringPage