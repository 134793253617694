import React from 'react'


import { useState } from 'react';
import { useEffect, useRef } from 'react';
import YouTube from 'react-youtube';

import { VscSettings } from 'react-icons/vsc'




import mediaProjectData from '../../components/data/mediaProject_data';

import './ProjectsPage.css'
import YouTubeVideoEmbed from '../../test/YouTubeVideoEmbed';


const ProjectsPage = () => {
    const [data, setData] = useState(mediaProjectData);
    const [navbarHeight, setNavbarHeight] = useState(0);
    useEffect(() => {
        // Function to calculate and set the navbar height dynamically
        const calculateNavbarHeight = () => {
            const navbar = document.getElementById('navbar'); // Replace 'navbar' with the actual ID of your navbar
            if (navbar) {
                const height = navbar.offsetHeight;
                setNavbarHeight(height);
            }
        };

        // Call the calculateNavbarHeight function when the component mounts and on window resize
        calculateNavbarHeight();
        window.addEventListener('resize', calculateNavbarHeight);

    })

    const containerStyles = {
        // Add other styles as needed
        top: `${navbarHeight}px`,
    };
    const clickHandlerFilterButton = (e) => {



        if (e.target.innerHTML === 'Close') {
            e.target.innerHTML = 'Filter'
        }
        else {
            e.target.innerHTML = 'Close'
        }


        const projectsbar = document.querySelector('.projects--links')

        // projectsbar.style.display = 'flex';
        if (projectsbar.style.display === 'flex') {
            projectsbar.style.display = 'none';
        }
        else {
            projectsbar.style.display = 'flex';
        }





    }



    useEffect(() => {
        // Initialize the video ID when the component mounts
        const initialVideoId = data.length > 0 ? data[0].videoLink : "";
        setVideoIdToPlay(initialVideoId);
    }, [data]); // 


    const playerRef = useRef(null);

    const onPlayerReady = (event) => {
        playerRef.current = event.target;
    };



    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [videoIdToPlay, setVideoIdToPlay] = useState("");
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsOverlayVisible(false);
            setIsVideoPlaying(false);
            if (playerRef.current && playerRef.current.internalPlayer) {
                playerRef.current.internalPlayer.pauseVideo();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const clickHandlerButtonShow = (videoId) => {
        setIsOverlayVisible(true);
        setIsVideoPlaying(true);
        setVideoIdToPlay(videoId); // Add this line to set the video ID
        const player = document.querySelector('.youtubeVideo')
        player.classList.remove('hidden');

    }
    const clickHandlerButtonRemove = (e) => {
        if (playerRef.current && playerRef.current.internalPlayer) {
            playerRef.current.internalPlayer.pauseVideo();
        }
        setIsOverlayVisible(false);
        setIsVideoPlaying(false);
        setVideoIdToPlay("");
        const player = document.querySelector('.youtubeVideo')
        player.classList.add('hidden')

    }
    const handleImageClick = (video) => {

        clickHandlerButtonShow(video);
    };

    const clickHandlerProjects = (e) => {
        const links = document.querySelectorAll('.projects--link');

        links.forEach(el => el.classList.remove('projects--link-active'));

        e.target.classList.add('projects--link-active');
        window.scrollTo(0, 0);


        const sortedData = mediaProjectData.filter(el => {


            // console.log(e.type);

            if (

                (el.type.split(' ').find(element => element === 'MusicVideos') && e.target.innerHTML === 'Music Videos') ||
                (el.type.split(' ').find(element => element === '2DAnimation') && e.target.innerHTML === '2D Animation') ||
                (el.type.split(' ').find(element => element === 'YoutubeVideos') && e.target.innerHTML === 'Youtube Videos') ||
                (el.type.split(' ').find(element => element === 'MotionGraphics') && e.target.innerHTML === 'Motion Graphics') ||
                (el.type.split(' ').find(element => element === 'AnimatedAdverstisments') && e.target.innerHTML === 'Animated Adverstisments') ||
                (el.type.split(' ').find(element => element === 'ExplainerVideos') && e.target.innerHTML === 'Explainer Videos') ||
                (el.type.split(' ').find(element => element === 'VideoEditing') && e.target.innerHTML === 'Video Editing') ||
                (el.type.split(' ').find(element => element === '3DAnimation') && e.target.innerHTML === '3D Animation') ||
                (el.same === 'AllProjects' && e.target.innerHTML === 'All Projects')
            ) {

                return el; // Include the element in the filtered array
            }
            return null; // Exclude the element from the filtered array
        }).filter(el => el !== null); // Remove the null values 

        setData(sortedData)





    }

    return (
        <section className="projects" id="projects" >
            <div className="container">
                <div className="projects--links">


                    <div className='projects--link projects--link-active' onClick={clickHandlerProjects}>
                        All Projects
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Music Videos
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        2D Animation
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Motion Graphics
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Animated Adverstisments
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Explainer Videos
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Video Editing
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        3D Animation
                    </div>
                </div>

                <div className="projects--data">
                    <div className="projects-empty"></div>
                    <div className="project--container">


                        {data.map((el, i) => {


                            return (
                                <div className={`projects--card ${el.type} ${el.same}`} key={i}>
                                    <div className='projects--card-image'>
                                        <YouTubeVideoEmbed videoId={el.videoLink} onImageClick={() => handleImageClick(el.videoLink)} />


                                    </div>
                                    <div
                                        className="projects--card-details"
                                        onClick={() => handleImageClick(el.videoLink)}>
                                        <h2>{el.title}
                                        </h2>
                                        <p>{el.para}</p>


                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>
            </div>
            <div className="filter-box" style={containerStyles}>
                <div className="filter-details" onClick={clickHandlerFilterButton}>
                    <VscSettings className='filter-icon' />
                    <div className='filter-title' >Filter</div>
                </div>
            </div>
            <div className={`youtubeVideo ${isOverlayVisible ? '' : 'hidden'}`} onClick={clickHandlerButtonRemove}>
                {isVideoPlaying && (
                    <YouTube
                        videoId={videoIdToPlay}
                        opts={{
                            width: '560',
                            height: '315',
                            playerVars: {
                                autoplay: 1,
                            },
                        }}
                        onReady={onPlayerReady}
                        onEnd={() => setIsVideoPlaying(false)}
                    />
                )}
                <button className="btn" onClick={clickHandlerButtonRemove}>
                    Close
                </button>

            </div>
        </section>
    )
}

export default ProjectsPage