import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { FaInstagram } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import vutukLogo from '../../assests/img-landingPage/main-bg-white.png'

const Footer = (props) => {
    return (
        <footer>

            <hr className='seperator' />

            <section className='footer' id='footer'>
                <div className="container">
                    <div className="title">
                        <Link to='/'>
                            <img src={vutukLogo} alt="" />
                        </Link>
                        <h3>Your Vision Our Execution</h3>
                    </div>
                    <div className="permalinks">
                        <h3>Pages</h3>
                        <Link to={props.home}>
                            Home
                        </Link>
                        <Link to={props.about}>
                            About Us
                        </Link>
                        <Link to={props.projects}>
                            Projects
                        </Link>
                        <Link to={props.contact}>
                            <span className='callBtn'>
                                Book a Call
                            </span>
                        </Link>
                    </div>
                    <div className="other-links">
                        <h3>Other Links</h3>
                        <div className="other-link">
                            <Link to={props.homeMedia}>
                                Vutuk Media
                            </Link>

                        </div>

                        <div className="other-link">
                            <Link to={props.homeDesign}>
                                Vutuk Design
                            </Link>

                        </div>

                        <div className="other-link">
                            <Link to={props.homeMain}>
                                Vutuk Home
                            </Link>

                        </div>

                    </div>
                    <div className="contact--perma">
                        <h2>Contact Us</h2>
                        <p>contact@vutuk.com</p>
                        <div className="socials">

                            <a href="https://www.instagram.com/iamvutuk/" target='_blank' rel="noopener noreferrer">
                                <FaInstagram className='socials-icon' />
                                <span className='extra-black'>
                                    Instagram
                                </span>

                            </a>

                            <a href="https://www.linkedin.com/company/vutuk/" target='_blank' rel="noopener noreferrer">
                                <FaLinkedin className='socials-icon' />
                                <span className='extra-black'>
                                    LinkedIn

                                </span>

                            </a>

                            <a href="https://twitter.com/iamvutuk" target='_blank' rel="noopener noreferrer">
                                <FaTwitter className='socials-icon' />
                                <span className='extra-black'>

                                    Twitter
                                </span>
                            </a>

                            <a href="https://www.facebook.com/iamvutuk/" target='_blank' rel="noopener noreferrer">
                                <FaFacebook className='socials-icon' />
                                <span className='extra-black'>
                                    Facebook

                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer