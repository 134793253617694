import React, { useState } from 'react'
import './Main.css'
import { Link } from 'react-router-dom'
import TypeWriter from '../../extra/Typewriter'
import IntroVideo from '../IntroVideos/Introvideos'
import { BsFillPlayFill } from 'react-icons/bs'
import 'font-awesome/css/font-awesome.min.css'; //



const Main = (props) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }
    const [playVideo, setPlayVideo] = useState(false);

    const handlePlayButtonClick = () => {
        const playVideo = document.querySelector('.intro-play');
        playVideo.classList.toggle('hidden')
        window.scrollTo(0, 0);
        setPlayVideo(true);

    }

    const typeWriter = props.typeWriter;
    return (
        <section className='main' id='main'>
            <div className="container">
                <div className="left">
                    <h2>{props.mainTitle}

                    </h2>
                    <div className="typewrite-content">
                        &#8203;
                        <TypeWriter data={typeWriter} />
                    </div>
                    <p>{props.mainPara}</p>
                    <div className="buttons">


                        <div className='intro-play-btn'>
                            <div className="intro-play-btn-img" onClick={handlePlayButtonClick}>
                                <div className="play-btn-animate"></div>
                                <div className='play-btn-triangle'>
                                    <BsFillPlayFill className='play-icon' />
                                </div>
                            </div>


                        </div>
                        <Link to={props.redirectContact}>
                            <button onClick={scrollToTop}
                                className='btn'>Book a Call</button>
                        </Link>


                        {/* <Link to={props.redirectProject}>
                            <button
                                className='btn'>Portfolio</button>
                        </Link> */}
                    </div>

                </div>
                <div className="right">
                    <img src={props.mainImage} alt="" />
                </div>
            </div>


            <div className="intro-play hidden">
                <IntroVideo embedId={props.mainVideoId} playVideo={playVideo} />
            </div>

        </section>
    )
}

export default Main