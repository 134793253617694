import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';

//MEDIA PAGES
import MediaPage from './mainPages/MediaPage/MediaPage';
import AboutMedia from './pages/about/AboutMedia'
import ProjectsMediaMainPage from './pages/projects/ProjectsMediaMainPage'

import ContactMedia from './pages/contact/ContactMedia'


//DESIGN PAGES
import EngineeringPage from './mainPages/EngineeringPage/EngineeringPage.jsx'
import AboutEngineering from './pages/about/AboutEngineering'
import ProjectsEngineeringMainPage from './pages/projects/ProjectsEngineeringMainPage'

import ContactEngineering from './pages/contact/ContactEngineering'
import LandingPage from './mainPages/LandingPage/LandingPage';


import Configurator from './components/Configurator/Configurator';

import NotFound from './mainPages/NotFound/NotFound';






function App() {


  return (
    <Router>


      <Routes>
        {/* LANDING PAGE */}
        <Route exact path='/' element={<LandingPage />}></Route>

        {/* MEDIA PAGES */}
        <Route exact path="/media" element={<MediaPage />} />
        <Route exact path="/media/about" element={<AboutMedia />} />
        <Route exact path="/media/projects" element={<ProjectsMediaMainPage />} />
        <Route exact path="/media/contact" element={<ContactMedia />} />



        {/* DESIGN PAGES */}
        <Route exact path="/design" element={<EngineeringPage />} />
        <Route exact path="/design/about" element={<AboutEngineering />} />
        <Route exact path="/design/projects" element={<ProjectsEngineeringMainPage />} />
        <Route exact path="/design/contact" element={<ContactEngineering />} />

        {/* NOT FOUND PAGE */}

        <Route path="*" element={<NotFound />} />


        {/* CONFIGURATOR */}

        <Route path="/configurator" element={<Configurator />} />

      </Routes>


    </Router>
  );


}

export default App;
