import React from 'react';

const YouTubeEmbed = ({ videoId, width, height }) => {
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div>
            <iframe
                width={width || '560'}
                height={height || '315'}
                src={embedUrl}
                allowFullScreen
                title="Embedded YouTube Video"
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
