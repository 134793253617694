
const mediaProjectData = [
    {

        title: "Vaskeperfume Animated Advertisement",
        para: "🎵 Embark on a soul-enriching musical journey with Riyaaz Qawwali - the esteemed custodians of Qawwali music! Get ready to immerse yourself in the art of Qawwali with our captivating animated intro, thoughtfully crafted by Vutuk Media. Join us as we unveil the heartwarming music lessons on Qawwali offered by the talented maestros of Riyaaz Qawwali.",
        videoLink: "sS69WxqqBrQ",
        type: 'AnimatedAdverstisments',
        same: 'AllProjects'
    },
    {
        title: "Ulari Med - Your Gateway to Seamless Healthcare | Animated Explainer Video by Vutuk Media",
        para: "💊 Welcome to Ulari Med - an innovative online platform revolutionizing the way you access medicine and pharma-related services! Join us on an enlightening journey through this captivating animated explainer video, expertly crafted by Vutuk Media. Discover how Ulari Med empowers individuals to conveniently buy medicine and access essential healthcare services.",
        videoLink: "YqTkzlshb5E",
        type: 'ExplainerVideos 2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Status Lead Animated Explainer video",
        para: "🎵 Embark on a soul-enriching musical journey with Riyaaz Qawwali - the esteemed custodians of Qawwali music! Get ready to immerse yourself in the art of Qawwali with our captivating animated intro, thoughtfully crafted by Vutuk Media. Join us as we unveil the heartwarming music lessons on Qawwali offered by the talented maestros of Riyaaz Qawwali.",
        videoLink: "RAdqGjMM3t4",
        type: 'ExplainerVideos 2DAnimation',
        same: 'AllProjects'
    },

    {
        title: "Animated Explainer - Introducing Matt Testing Machine by Nebulaa Technology",
        para: "Unravel the cutting-edge technology behind the Matt Testing Machine by Nebulaa Technology through this captivating animated explainer video. 🚀🎬",
        videoLink: "LlonPksdjCA",
        type: 'ExplainerVideos 2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Animated Hand Safety Story - A Heartwarming Conversation between Father and Son | Mars Wrigley",
        para: "Embark on an animated journey that tugs at your heartstrings as Vutuk Media proudly presents 'A Tale of Hand Safety,' a heartwarming conversation between a father and son, commissioned by Mars Wrigley. 🌟👐",
        videoLink: "bHO3rrQoT9s",
        type: '2DAnimation',
        same: 'AllProjects'
    },

    {
        title: "Animated Advertisement - Unleash the Power of AI & Machine Learning with LogicMojo | Vutuk Media",
        para: "Get ready to embark on an exhilarating learning journey with LogicMojo, the ultimate destination for AI and Machine Learning enthusiasts. 🚀🧠",
        videoLink: "5ZJZN74W_LE",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Animated Advertisement - Master Coding Skills with LogicMojo | Vutuk Media",
        para: "Embark on a coding adventure like never before with LogicMojo, the ultimate online tutor website for aspiring programmers. Get ready to unleash your creativity and master coding skills with our vibrant animated advertisement, presented by Vutuk Media. 🌐💻",
        videoLink: "FeaR81-zDFU",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },

    {
        title: "Animated Instruction - Simplifying ITR Submission with Gov.gi | Vutuk Media",
        para: "Unlock the secrets to seamless tax filing with Gov.gi's Animated Instruction Video, proudly presented by Vutuk Media. 🌐💼",
        videoLink: "OC6q2mhc8gY",
        type: 'MotionGraphics',
        same: 'AllProjects'
    },
    {
        title: "Flockchain - Empowering the Future of Cryptocurrency | Animated Explainer Video by Vutuk Media",
        para: "💰 Step into the realm of cryptocurrency with Flockchain - a cutting-edge platform revolutionizing the way we engage with digital assets! Join us on an enlightening journey through this captivating animated explainer video, masterfully crafted by Vutuk Media. Discover how Flockchain empowers users to navigate the world of cryptocurrency with confidence and security.",
        videoLink: "x28qo-2ADdk",
        type: '2DAnimation MotionGraphics',
        same: 'AllProjects'
    },
    {
        title: "CodeUpKid - Empowering Young Minds with Coding Skills | Animated Ad by Vutuk Media",
        para: "🚀 Ignite the spark of coding brilliance in your kids with CodeUpKid - an engaging online learning platform designed to teach coding to young minds! Join us on an exciting journey through this captivating animated ad, skillfully crafted by Vutuk Media. Discover how CodeUpKid makes coding fun, creative, and accessible for kids, unlocking their potential in the digital world.",
        videoLink: "eBc6-t9A8kM",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "CodeupKid - Unleashing the Creativity of Young Coders | Animated Ad by Vutuk Media",
        para: "🚀 Ignite the coding spark in your kids with CodeupKid - an innovative online learning platform that makes coding fun and engaging for young minds! Join us on an exciting journey through this captivating animated ad, expertly brought to life by Vutuk Media. Discover how CodeupKid nurtures creativity and empowers young learners to become future tech wizards!",
        videoLink: "BAx0BOpfOg8",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },
    {

        title: "CodeUpKid - Empowering Young Minds with Coding | Animated Ad by Vutuk Media",
        para: "🚀 Prepare your kids for a future of innovation and creativity with CodeUpKid - the ultimate online learning platform designed to teach coding to young minds! Join us on an exciting journey through this captivating animated ad, skillfully brought to life by Vutuk Media. Discover how CodeUpKid ignites a passion for coding and fosters a generation of young tech enthusiasts.",
        videoLink: "klc1dE378Zc",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },
    {

        title: "Ceeina App - Your Personalized Health Companion | Animated Explainer Video by Vutuk Media",
        para: "🍎 Discover the revolutionary Ceeina app, your ultimate health companion designed to empower you in managing your diet as per your specific health conditions such as diabetes, blood pressure, and more. Join us in this captivating animated explainer video, a cutting-edge creation by Vutuk Media, as we unveil the power of personalized nutrition and health management.",
        videoLink: "9GbTDrCBJNA",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {

        title: "CCLearning - Empowering Office Staff with Online Work Training | Explainer Video by Vutuk Media",
        para: "📚 Elevate your professional skills with CCLearning - a comprehensive online course designed to empower office staff with top-notch work training! Join us on an enlightening journey through this captivating animated explainer video, masterfully crafted by Vutuk Media. Discover how CCLearning enhances knowledge and proficiency for office staff, ensuring growth and success in their careers.",
        videoLink: "5lhDJdIw1sk",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Aquatrax - Empowering Water Management | Animated Explainer Video by Vutuk Media",
        para: "💧 Embrace the power of efficient water management with Aquatrax - an innovative software designed to prevent water wastage and optimize its usage with precise statistics! Join us on an enlightening journey through this captivating animated explainer video, skillfully crafted by Vutuk Media. Discover how Aquatrax empowers individuals and organizations to make the best use of water resources.",
        videoLink: "OhMOFo_ONTY",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Amazetech - Revolutionizing Healthcare Management | Animated Explainer Video by Vutuk Media",
        para: "🏥 Embrace the future of healthcare management with Amazetech - the ultimate software designed to streamline the work of doctors and hospitals, easing their daily operations! Join us on an enlightening journey through this captivating animated explainer video, skillfully crafted by Vutuk Media. Discover how Amazetech empowers healthcare professionals with seamless efficiency and precision.",
        videoLink: "7nNmkXXW7Wg",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {

        title: "Animated explainer for infinitus mart",
        para: "🎵 Embark on a soul-enriching musical journey with Riyaaz Qawwali - the esteemed custodians of Qawwali music! Get ready to immerse yourself in the art of Qawwali with our captivating animated intro, thoughtfully crafted by Vutuk Media. Join us as we unveil the heartwarming music lessons on Qawwali offered by the talented maestros of Riyaaz Qawwali.",
        videoLink: "dpcKQNbk3VM",
        type: '2DAnimation ExplainerVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "GB Messenger - Your Private Chat Companion | Animated Ad by Vutuk Media",
        para: "📱 Experience a whole new level of privacy and convenience with GB Messenger - the ultimate messaging app that lets you read WhatsApp messages without being online and chat without being visible to other users! Join us on an animated journey through this captivating ad, brought to life by Vutuk Media. Discover how GB Messenger empowers you to communicate discreetly and confidently.",
        videoLink: "mkVkAT_U7sM",
        type: '2DAnimation AnimatedAdverstisments',
        same: 'AllProjects'
    },
    {
        title: "Airtime4u - Connecting You with Seamless Phone Calls | Animated Ad by Vutuk Media",
        para: "📞 Stay connected with ease using Airtime4u - the ultimate service to purchase phone credit and make hassle-free phone calls! Join us on an animated journey through this captivating ad, expertly edited by Vutuk Media. Discover how Airtime4u empowers you with seamless communication, anytime, anywhere.",
        videoLink: "QXy4GWpdVq0",
        type: '2DAnimation AnimatedAdverstisments',
        same: 'AllProjects'
    },
    {
        title: "Balancing Crop Nutrition | Animated Video by Vutuk Design for Amuja Cement Foundation | Vutuk Media",
        para: "🌾 Step into the world of sustainable agriculture as two curious minds engage in a thought-provoking discussion about the crucial role of fertilizers and their impact on crops. Join us in this captivating animated video, an extraordinary creation by Vutuk Design in collaboration with Amuja Cement Foundation, as we explore the delicate balance between fertilizer usage and responsible farming practices.",
        videoLink: "rYztUmOpO0w",
        type: '2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Cultivating Cotton with Biodiversity | Animated Video by Vutuk for Amuja Cement Foundation",
        para: "🌾 Step into the world of sustainable agriculture as two curious minds engage in a thought-provoking discussion about the crucial role of fertilizers and their impact on crops. Join us in this captivating animated video, an extraordinary creation by Vutuk Design in collaboration with Amuja Cement Foundation, as we explore the delicate balance between fertilizer usage and responsible farming practices.",
        videoLink: "1uy9FQAEp2s",
        type: '2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Your Gateway to Business Buying and Selling | Animated Explainer Video | Edited by Vutuk Media",
        para: "🏢 Discover a seamless and secure way to buy and sell businesses with Allbiz Dealroom! Join us on an enlightening journey through this engaging Animated Explainer video, expertly edited by Vutuk Media. Explore how Allbiz Dealroom simplifies business transactions with proper paperwork and trusted processes.",
        videoLink: "Lh-nneHGL9Y",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Allbiz Dealroom - Simplifying Business Transactions | Animated Explainer Video",
        para: "🏢 Unlock the gateway to seamless business transactions with Allbiz Dealroom - the ultimate platform to buy and sell businesses with proper paperwork! Join us on a captivating journey through this animated explainer video, skillfully edited by Vutuk Media. Discover how Allbiz Dealroom streamlines the process, ensuring secure and efficient deals for entrepreneurs worldwide.",
        videoLink: "M45SxQT9Et8",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Introducing Allbiz Dealroom | Animated Explainer Video",
        para: "🏢 Step into a world of efficient business deals with Allbiz Dealroom - the ultimate platform that streamlines the process of buying and selling businesses with proper paperwork. Join us on an enlightening journey through this animated explainer video, masterfully edited by Vutuk Media. Discover how Allbiz Dealroom empowers entrepreneurs to engage in seamless and secure transactions.",
        videoLink: "cP9OFoNFMZc",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Discover Allbiz Dealroom - Your Gateway to Secure Business Transactions | Animated Explainer Video",
        para: "🏢 Explore the world of seamless business transactions with Allbiz Dealroom - the ultimate platform that streamlines buying and selling businesses with proper paperwork! Join us on an enlightening journey through this captivating animated explainer video, meticulously edited by Vutuk Media. Discover how Allbiz Dealroom empowers entrepreneurs with a secure and efficient process for business deals.",
        videoLink: "TvATFK9-UVQ",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Allbiz Dealroom - Your Gateway to Seamless Business Transactions | Animated Explainer",
        para: "🏢 Step into the world of hassle-free business transactions with Allbiz Dealroom - the ultimate platform to buy and sell businesses with proper paperwork! Join us on an illuminating journey through this captivating animated explainer video, expertly edited by Vutuk Media. Discover how Allbiz Dealroom streamlines the process, ensuring smooth and secure business deals.",
        videoLink: "H29qFHfF0K8",
        type: '2DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Sar mein Sauda - An Enchanting Qawwali Journey | Riyaaz Qawwali",
        para: "Are you ready to embark on an enchanting musical journey that will touch your soul and leave you mesmerized? Introducing 'Sar mein Sauda,' a soul-stirring qawwali composition by the immensely talented Riyaaz Qawwali, with heartwarming lyrics penned by the esteemed Firaq Gaurakhpuri.",
        videoLink: "ER06xSb1ptY",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Sakoot-e-Shaam - A Captivating Qawwali Symphony | Riyaaz Qawwali",
        para: "Step into a realm of sublime tranquility with 'Sakoot- e - Shaam,' an ethereal qawwali symphony composed by the maestros of melody, Riyaaz Qawwali. 🎶🌙",
        videoLink: "b4Q4iBOaSWY",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Mere Rashke Kamar - A Mesmerizing Qawwali Fusion | Riyaaz Qawwali with Ibru Art 1",
        para: "Prepare to be enchanted by the timeless classic 'Mere Rashke Kamar, ' presented in an enchanting qawwali rendition by the illustrious Riyaaz Qawwali, in collaboration with the soulful artistry of Ibru Art. 🎶🌟",
        videoLink: "SPebalaocug",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Ye Ishq Mastana - A Soulful Qawwali | Composed by Riyaaz Qawwali | Edited by Vutuk Media",
        para: "🎶 Surrender to the intoxicating melodies of 'Ye Ishq Mastana' - a soul-stirring qawwali composed by the talented Riyaaz Qawwali, with profound lyrics by the revered Sant Kabir Das. Immerse yourself in the depths of divine love as Vutuk Media presents this captivating music video on their channel.",
        videoLink: "lbEcLkeu7Rc",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Ik Ghar Rab Da - A Soulful Qawwali | Composed by Riyaaz Qawwali | Edited by Vutuk Media",
        para: "🎶 Immerse yourself in the mesmerizing melodies of 'Ik Ghar Rab Da' - a soulful qawwali composed by the renowned Riyaaz Qawwali, and expertly edited by Vutuk Media. Join us on a spiritual journey of devotion and divine love as we present this captivating music video on Vutuk Media's channel.",
        videoLink: "KKCWUEHdbU4",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Bastiyan Dhoondh Rahi Hai - A Soulful Quest | Riyaaz Qawwali",
        para: "Embark on a heartfelt journey of self-discovery with 'Bastiyan Dhoondh Rahi Hai,' a soul-stirring qawwali composition by the maestros of music, Riyaaz Qawwali. 🎶🌠",
        videoLink: "f3qmfL2W3UA",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Ayi Hai Qayamat - A Soul-Stirring Qawwali Revelation | Riyaaz Qawwali",
        para: "Prepare to be spellbound by the mesmerizing melodies of 'Ayi Hai Qayamat,' a qawwali masterpiece composed by the eminent Riyaaz Qawwali and written by the visionary poet, Firaq Gaurakhpuri. 🎶🌌",
        videoLink: "qdiBPKzWIfI",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Ayi Hai Qayamat - An Intense Qawwali Revelation | Riyaaz Qawwali",
        para: "Unveil the mystical secrets of existence with 'Ayi Hai Qayamat,' an awe-inspiring qawwali composition brought to life by the virtuoso Riyaaz Qawwali. 🎶🌌",
        videoLink: "Ew18nzYmWW8",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Ab Aksar Chup - Unveiling the Silence | Riyaaz Qawwali",
        para: "Indulge in the enchanting melodies of 'Ab Aksar Chup,' a soulful qawwali composition by the maestros, Riyaaz Qawwali. 🎶🌄",
        videoLink: "OL2agszSFPc",
        type: 'MusicVideos VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Video for MST",
        para: "🎓 Unlock the doors to success with Master Skill Training (MST) - the ultimate platform for online courses that cater to your learning needs! Join us on an enlightening journey through this engaging explainer video, meticulously edited by Vutuk Media. Discover how MST empowers individuals with knowledge and skills to thrive in today's dynamic world.",
        videoLink: "24LFTSr2KN0",
        type: 'ExplainerVideos 2DAnimation VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Unleashing the Future of Farming Management | N-gauge App 3D animated Explainer Video",
        para: "🌾 Step into the world of cutting-edge farming management with the revolutionary N-gauge app! Join us on an immersive journey of agricultural innovation through this captivating 3D explainer video, expertly edited by Vutuk Media. Discover how N-gauge empowers farmers with advanced tools for precision farming and crop management.",
        videoLink: "Q8tOkMI071k",
        type: '3DAnimation ExplainerVideos',
        same: 'AllProjects'
    },
    {
        title: "Words of Wisdom from the CEO of CRIISP | Edited by Vutuk Media",
        para: "📢 Prepare to be inspired as we present a powerful message from the CEO of CRIISP! Join us in this impactful video where words of wisdom and vision are shared, edited and presented to perfection by Vutuk Media. Witness the essence of leadership and innovation as we bring you insights from a visionary leader.",
        videoLink: "F_tqRq4HYIg",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Master Sweepers - Sweeper Tractor | The Future of Cleanliness | Edited by Vutuk Media",
        para: "🚜 Step into the future of cleanliness with the revolutionary Sweeper Tractor from Master Sweepers! Witness the power and efficiency of this cutting-edge cleaning machine, expertly edited by Vutuk Media. Join us on this journey of innovation and sustainability as we unveil the ultimate solution for maintaining pristine surroundings.",
        videoLink: "UdLMqLEG_oE",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Introducing the Sweeper Tractor | Master Sweepers | Edited by Vutuk Media",
        para: "🚜 Unleash the power of cleanliness with the groundbreaking Sweeper Tractor from Master Sweepers! Prepare to be amazed by this innovative marvel that redefines the way we keep our surroundings spick and span. Join us in this captivating video, expertly edited by Vutuk Media, as we showcase the Sweeper Tractor in all its sweeping glory.",
        videoLink: "x0QnZ1bXyz4",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Unleashing the Magic of a Portable Tennis Court | Edited by Vutuk Media",
        para: "🎾 Step into a world where the boundaries of possibility are redefined with the incredible Portable Tennis Court! Witness the awe-inspiring magic of this innovative creation, masterfully composited and edited by Vutuk Media. Join us on this extraordinary journey as we showcase the ultimate tennis experience that knows no limits.",
        videoLink: "fnvoZqMzp1c",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Love in Full Bloom | Navpreet & Mandeep's Pre-Wedding Video Edit | Edited by Vutuk Media",
        para: "💍 Love blossoms in the air as we present 'Love in Full Bloom' - a heartwarming pre-wedding video edit capturing the magical moments shared by Navpreet and Mandeep. Join us in this enchanting journey of love and togetherness, masterfully edited by Vutuk Media, as we celebrate the beautiful bond between two souls destined for a lifetime of happiness.",
        videoLink: "vEXYv96E-ic",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Aerial Symphony - Mesmerizing Drone Footage Montage | Edited by Vutuk Media",
        para: "🚁 Soar to new heights with 'Aerial Symphony' - an awe-inspiring montage of breathtaking drone footage, masterfully edited by Vutuk Media for Mr. Drone. Join us on this visual journey as we explore stunning landscapes, architectural marvels, and captivating aerial vistas in this cinematic masterpiece.",
        videoLink: "2x6Pr6BhzR0",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Celebrating Our Beloved Pastor | Birthday Montage | Edited by Vutuk Media",
        para: "🎂 Join us in celebrating a remarkable milestone in the life of our beloved pastor! In honor of her special day, we present this heartwarming birthday montage, expertly crafted by Vutuk Media. This touching tribute captures cherished memories, heartfelt messages, and the love and admiration of our congregation.",
        videoLink: "GkMvI7F2Vr4",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Adorable Kid's Dance | A Heartwarming Performance | Video Editing by Vutuk Media",
        para: "🕺 Get ready to witness an enchanting moment of pure joy and exuberance as an adorable kid showcases their dance skills! Join us in this heartwarming dance video, lovingly recorded by the proud parent and expertly edited by Vutuk Media, as we celebrate the sheer innocence and happiness that dance brings to little hearts.",
        videoLink: "QBs72XFyo14",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Bone Drums Snare KickStarter | Elevating Rhythm | Video Editing by Vutuk Media",
        para: "🥁 Unleash the power of rhythm with Bone Drums Snare - the game-changing innovation that takes drumming to a whole new level! Join us in this electrifying KickStarter video, expertly edited by Vutuk Media, as we showcase the revolutionary Bone Drums Snare and its unparalleled potential to elevate your drumming experience.",
        videoLink: "CqZkOxgiy80",
        type: 'VideoEditing',
        same: 'AllProjects'
    },
    {
        title: "Discover Africa's Rich Diversity | Animated Jigsaw Puzzle | Animation by Vutuk Media",
        para: "🌍 Immerse yourself in the captivating world of Africa's cultural tapestry with our mesmerizing animated jigsaw puzzle! Join us on a journey through the vibrant continent, where different cultures and ethnicities come together in a beautiful mosaic. Created with love and expertise by Vutuk Media, this animated masterpiece showcases Africa's rich diversity in a unique and interactive way.",
        videoLink: "KTNB9PYjsrI",
        type: 'AnimatedAdverstisments 2DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Slingshot - The Ultimate Car Game | Official Trailer | 3D Animation by Vutuk Media",
        para: "🏎️ Buckle up and get ready for the ride of a lifetime! Introducing 'Slingshot, ' the adrenaline-pumping car game that will take your gaming experience to a whole new level. Join us in this action-packed official trailer, where 3D animation and video editing by Vutuk Media bring the heart-pounding excitement of Slingshot to life.",
        videoLink: "SHym6w6Uk0E",
        type: '3DAnimation',
        same: 'AllProjects'
    },
    {
        title: "Nutirsense Testimonial - Controlling Blood Sugar Made Easy |  Vutuk Media",
        para: "🩸 Are you struggling to manage your blood sugar levels effectively? Meet Nutirsense, the revolutionary solution that empowers you to take control of your blood sugar like never before. Join us in this powerful testimonial video, expertly edited by Vutuk Media, as real users share their life-changing experiences with Nutirsense.",
        videoLink: "rjBt-ehbXtY",
        type: 'VideoEditing',
        same: 'AllProjects'
    },




]


export default mediaProjectData;