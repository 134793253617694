import React from 'react'
import mainVutukWhite from '../../assests/img-landingPage/main-bg-white.png'

import { Link } from 'react-router-dom'
import './NotFound.css'


const NotFound = () => {
    return (
        <div className='not-found'>
            <div className="container">
                <div className="logo">
                    <img src={mainVutukWhite} alt="" />
                </div>
                <h1>404 Not Found</h1>
                <Link to='/'>
                    <h4>Go To Main Page</h4>
                </Link>

            </div>
        </div>
    )
}

export default NotFound