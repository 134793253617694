import { useState } from 'react';
import { Link } from 'react-router-dom';
import mainVutukWhite from '../../assests/img-landingPage/Vutuk-Design-and-media.png'
// import mainVutukBlack from '../../assests/img-landingPage/main-bg-white.png'
import mainVutukMedia from '../../assests/img-landingPage/Vutuk-Media-Logo-White.png'
import mainVutukDesign from '../../assests/img-landingPage/Vutuk-Design-Logo-White.png'



// CUSTOM CSS
import './LandingPage.css'
import Clients from '../../components/clients/Clients';




const LandingPage = () => {
    const [mediaImageBg, setMediaImageBg] = useState(false);
    const [engineeringImageBg, setEngineeringImageBg] = useState(false);
    const [textColor, setTextColor] = useState(false);
    const [currentLogo, setCurrentLogo] = useState(mainVutukWhite);




    const mediaButtonMouseOver = () => {
        setMediaImageBg(true);
        setTextColor(true);
        setCurrentLogo(mainVutukMedia);
    }
    const mediaButtonMouseLeave = () => {
        setMediaImageBg(false);
        setTextColor(false);
        setCurrentLogo(mainVutukWhite);
    };



    const engineeringButtonMouseOver = () => {
        setEngineeringImageBg(true);
        setTextColor(true);
        setCurrentLogo(mainVutukDesign);
    }
    const engineeringButtonMouseLeave = () => {
        setEngineeringImageBg(false);
        setTextColor(false);
        setCurrentLogo(mainVutukWhite)
    }


    return (
        <section className="landingPage" id="landingPage">
            <div className={`container ${mediaImageBg ? 'mediaBg' : ' '} ${engineeringImageBg ? 'engineeringBg' : ' '}`} >
                <div className="top">
                    <div className="logo">
                        <img src={currentLogo} alt="" />
                    </div>
                    <div className="details">
                        <h2 className={`${textColor ? 'onHover' : ''}`}>Your Vision Our Execution</h2>
                        <div className="buttons">

                            <Link to={'/media'}>
                                <button className="btn" onMouseOver={mediaButtonMouseOver}
                                    onMouseLeave={mediaButtonMouseLeave}
                                >Video Services</button>
                            </Link>
                            <Link to={'/design'}>
                                <button className="btn"
                                    onMouseOver={engineeringButtonMouseOver}
                                    onMouseLeave={engineeringButtonMouseLeave}> Engineering Services</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <hr className='seperator' />

                <Clients />



            </div>
        </section>
    )
};

export default LandingPage;
