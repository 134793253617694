import React from 'react'
import './About.css'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import AboutUsPage from './AboutUsPage'
import SwitchLogoMedia from '../../assests/logo/SwitchMedia.png'
import CTA from '../../components/cta/CTA'

import DesignLogo from '../../assests/logo/Vutuk-Logo_design.png'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <Navbar
                navlogo={'/design'}
                logo={DesignLogo}
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'}

            />
            <AboutUsPage />
            <Footer
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'} />

            <Link to='/media'>
                <CTA logoImage={SwitchLogoMedia} />

            </Link>
        </>
    )
}

export default About