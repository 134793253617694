import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RxCross1 } from 'react-icons/rx'

import './Navbar.css'
import { Link } from 'react-router-dom'

const Navbar = (props) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const clickHandlerNavbarHam = (e) => {
        const navBox = document.querySelector('.nav-box')
        const btnHam = document.querySelector('.icon--ham');
        const btnCross = document.querySelector('.icon--cross');
        console.log(btnHam);
        navBox.style.display = 'flex';
        btnCross.style.display = 'block';
        btnHam.style.display = 'none';
    }
    const clickHandlerNavbarCross = (e) => {
        const navBox = document.querySelector('.nav-box');
        const btnHam = document.querySelector('.icon--ham');
        const btnCross = document.querySelector('.icon--cross');

        navBox.style.display = 'none';
        btnCross.style.display = 'none';
        btnHam.style.display = 'block';

    }

    return (
        <div className='navbar' id='navbar'>
            <div className="navbar--items">
                {/* LOGO */}
                <div className="logo">

                    <Link to={props.navlogo}>
                        <img src={props.logo} alt="VUTUK" onClick={scrollToTop} />
                    </Link>
                </div>


                {/* LINKS */}
                <ul className='navbar--links nav-box'>
                    <li className='navbar--link'>
                        <Link to={props.home} onClick={scrollToTop}>
                            Home
                        </Link>
                    </li>

                    <li className='navbar--link'>
                        <Link to={props.about} onClick={scrollToTop}>
                            About Us
                        </Link>
                    </li>

                    <li className='navbar--link'>
                        <Link to={props.projects} onClick={scrollToTop}>
                            Projects
                        </Link>
                    </li>

                    <li className='navbar--link'>
                        <Link to={props.contact} onClick={scrollToTop}>
                            <span className="callBtn">
                                Book a Call
                            </span>
                        </Link>
                    </li>

                </ul>
                <GiHamburgerMenu className=' navbar--icon icon--ham' onClick={clickHandlerNavbarHam} />
                <RxCross1 className=' navbar--icon icon--cross' onClick={clickHandlerNavbarCross} />
            </div>
        </div>
    )
}

export default Navbar