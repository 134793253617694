import React from 'react'

import { BiUser, BiVideo } from 'react-icons/bi'
import { GiAchievement } from 'react-icons/gi'

import team from '../../components/data/team'

import Clients from '../../components/clients/Clients'
import imgMedia from '../../assests/img-media/main-1.png'
import imgDesign from '../../assests/img-design/main-1.png'
import YouTubeEmbed from '../../components/embedVideo/YouTubeEmbed'


import './AboutUsPage.css'

const AboutUsPage = () => {
    return (
        <section className='aboutus' id='aboutus'>
            <div className="container">
                <div className="achievements">
                    <div className="left">
                        <img src='https://res.cloudinary.com/dqrs6f17j/image/upload/v1695021381/achievements-bg_lf8io9.png' alt="" />
                    </div>
                    <div className="right">
                        <div className="achievements--box">
                            <h1>Achievements</h1>
                            <p>"Vutuk: Where Engineering Meets Creativity. With a dynamic team of skilled engineers and visionary video editors, we've garnered a proven track record of delivering exceptional digital solutions. Our client-focused approach has led to a diverse portfolio of successful collaborations, spanning industries and igniting digital experiences that leave a lasting impact. At Vutuk, we don't just achieve — we elevate visions to reality."</p>

                            <div className="box--container">
                                <div className="achievements--box_cards">
                                    <div className="achievements--box_card">
                                        <div className='achievements--cards-logo'> <BiVideo className='achievements-icon' /></div>
                                        <h2>2000+</h2>
                                        <small>Projects</small>
                                    </div>
                                </div>
                                <div className="achievements--box_cards">
                                    <div className="achievements--box_card">
                                        <div className='achievements--cards-logo'> <BiUser className='achievements-icon' /></div>
                                        <h2>500+</h2>
                                        <small>Clients</small>
                                    </div>
                                </div>
                                <div className="achievements--box_cards">
                                    <div className="achievements--box_card">
                                        <div className='achievements--cards-logo'> <GiAchievement className='achievements-icon' /></div>
                                        <h2>50+</h2>
                                        <small>Clients in Countries</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="about-media-container">

                    <div className="left">
                        <h2>VIDEO SERVICES</h2>
                        <p>

                            Video Editing: With a keen eye for detail and a mastery of the latest editing tools, our video editing team transforms raw footage into compelling narratives. We bring stories to life, ensuring that every frame aligns with your vision.
                        </p>
                        <p>
                            Video Production: From conceptualization to post-production, our video production team handles every aspect of creating stunning visuals. We excel in producing videos that leave a lasting impression.
                        </p>
                        <p>
                            Graphic Designing: Our graphic designers are adept at translating ideas into striking visuals. Whether it's designing logos, branding materials, or marketing collateral, we craft designs that resonate with your target audience.
                        </p>
                        <p>
                            3D Animation: Dive into the world of three-dimensional creativity with our 3D animation experts. We breathe life into objects and characters, making them leap off the screen.

                        </p> <p>
                            2D Animation: For a more traditional yet enchanting animation style, our 2D animation specialists craft animations that evoke nostalgia and capture attention.</p>
                    </div>
                    <div className="right">
                        <img src={imgMedia} alt="" />
                    </div>
                </div>

                <div className="about-design-container"><div className="left">
                    <img src={imgDesign} alt="" />
                </div>

                    <div className="right">
                        <h2>ENGINEERING SERVICES</h2>
                        <p>

                            At Vutuk, engineering is more than just a profession; it's our passion. Our Machine & Product Design services are anchored in meticulous attention to detail and cutting-edge technology. We conceptualize, design, and refine machines and products to exacting specifications, ensuring functionality, durability, and aesthetic appeal.
                        </p>
                        <p>
                            Our FEA & CFD Analysis capabilities empower clients to make informed decisions by simulating the real-world performance of their products and systems. With state-of-the-art software and a team of simulation experts, we identify potential issues, optimize designs, and drive efficiency.
                        </p>
                        <p>
                            Concept Development at Vutuk is the crucible where ideas are forged into actionable concepts. We collaborate closely with our clients to breathe life into their visions, ensuring that concepts align seamlessly with market needs and technological possibilities.
                        </p>
                        <p>
                            Our Product Rendering services transform concepts into visually stunning representations. Through meticulous detailing and photorealistic rendering, we bring your designs to life, making them compelling and market-ready.

                        </p>
                        <p>
                            For dynamic storytelling and engaging presentations, our 3D Animations elevate your ideas to the next level. We blend creativity with technology to craft animations that captivate, educate, and inspire.</p>
                    </div>

                </div>

                <div className='about-intro'>
                    <YouTubeEmbed videoId={"bQiskp910_0"} width="560" height="315" className="about-intro-video" />

                    <YouTubeEmbed videoId={"MkZxdZvozes"} width="560" height="315" />


                </div>

                {/* CLIENTS  */}
                <Clients />

                {/* TEAM SECTION */}
                <div className="team">


                    <h2>Meet Our Team</h2>



                    <div className="team--container">
                        {team.map((el, i) => {
                            return (
                                <div className="team--card" key={i}>
                                    <div className="image">
                                        <img src={el.image} alt="" />
                                    </div>
                                    <h2>{el.name}</h2>
                                    <h4>{el.profession}</h4>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </section>

    )
}

export default AboutUsPage