import React from 'react'


import './CTA.css'
const CTA = (props) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }



    return (
        <div className='cta' id='cta' >
            <div className='cta--container'>

                <div className='bottom'>
                    <img src={props.logoImage} alt="" onClick={scrollToTop} />
                </div>
            </div>
        </div>
    )
}

export default CTA