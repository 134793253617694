import React, { useRef } from 'react';
import YouTube from 'react-youtube';

const IntroVideo = ({ embedId, playVideo }) => {
    const playerRef = useRef(null);

    const closeYoutubePlayer = () => {
        if (playerRef.current) {
            // playerRef.current.internalPlayer.pauseVideo()
            console.log(playerRef.current.pauseVideo()); // Pause the video
        }

        const player = document.querySelector('.intro-play');
        player.classList.toggle('hidden');
    };

    return (
        <div className={`intro-play ${playVideo ? '' : 'hidden'}`} onClick={closeYoutubePlayer}>
            <YouTube className='youtube'
                videoId={embedId}
                opts={{
                    width: '560',
                    height: '315',
                    playerVars: {
                        autoplay: playVideo ? 1 : 0,
                    },
                }}
                onReady={(event) => (playerRef.current = event.target)}
            />
        </div>
    );
};

export default IntroVideo;
