import React from 'react'
import './Contact.css'
import Navbar from '../../components/navbar/Navbar'
import ContactUsPage from './ContactUsPage'
import Footer from '../../components/footer/Footer'
import mediaLogo from '../../assests/logo/Vutuk_Media_white_logo.png'
import SwitchLogoDesign from '../../assests/logo/switchDesign.png'

import CTA from '../../components/cta/CTA'
import { Link } from 'react-router-dom'

const Contact = () => {
    return (
        <>
            <Navbar logo={mediaLogo}
                navlogo={'/media'}
                home={'/media'}
                about={'/media/about'}
                projects={'/media/projects'}
                contact={'/media/contact'} />

            <ContactUsPage />
            <Footer home={'/media'}
                about={'/media/about'}
                projects={'/media/projects'}
                contact={'/media/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'} />

            <Link to={'/design'}>
                <CTA logoImage={SwitchLogoDesign} />
            </Link>
        </>
    )
}

export default Contact