import React from 'react'
import ImageViewer from 'awesome-image-viewer'


import { useState } from 'react';
import { useEffect, useRef } from 'react';
import YouTube from 'react-youtube';

import engineeringProjectsData from '../../components/data/engineeringProject_data';
import engineeringProjectImage_data from '../../components/data/engineeringProjectImage_data'
import YouTubeVideoEmbed from '../../test/YouTubeVideoEmbed';

import './ProjectsPage.css'
import { VscSettings } from 'react-icons/vsc'


const ProjectsPage = () => {

    const [data, setData] = useState(engineeringProjectsData);
    const [dataImg, setDataImg] = useState(engineeringProjectImage_data);
    const [navbarHeight, setNavbarHeight] = useState(0);

    useEffect(() => {
        // Function to calculate and set the navbar height dynamically
        const calculateNavbarHeight = () => {
            const navbar = document.getElementById('navbar'); // Replace 'navbar' with the actual ID of your navbar
            if (navbar) {
                const height = navbar.offsetHeight;
                setNavbarHeight(height);
            }
        };

        // Call the calculateNavbarHeight function when the component mounts and on window resize
        calculateNavbarHeight();
        window.addEventListener('resize', calculateNavbarHeight);

    })

    const containerStyles = {
        // Add other styles as needed
        top: `${navbarHeight}px`,
    };
    const clickHandlerFilterButton = (e) => {



        if (e.target.innerHTML === 'Close') {
            e.target.innerHTML = 'Filter'
        }
        else {
            e.target.innerHTML = 'Close'
        }


        const projectsbar = document.querySelector('.projects--links')

        // projectsbar.style.display = 'flex';
        if (projectsbar.style.display === 'flex') {
            projectsbar.style.display = 'none';
        }
        else {
            projectsbar.style.display = 'flex';
        }





    }





    useEffect(() => {
        // Initialize the video ID when the component mounts
        const initialVideoId = data.length > 0 ? data[0].videoLink : "";
        setVideoIdToPlay(initialVideoId);
    }, [data]); // 


    const playerRef = useRef(null);

    const onPlayerReady = (event) => {
        playerRef.current = event.target;
    };


    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [videoIdToPlay, setVideoIdToPlay] = useState("");
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsOverlayVisible(false);
            setIsVideoPlaying(false);
            if (playerRef.current && playerRef.current.internalPlayer) {
                playerRef.current.internalPlayer.pauseVideo();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const ClickHandlerImageShow = (data) => {
        new ImageViewer({
            images: data
        })
    }


    const clickHandlerButtonShow = (videoId) => {
        setIsOverlayVisible(true);
        setIsVideoPlaying(true);
        setVideoIdToPlay(videoId); // Add this line to set the video ID

        const player = document.querySelector('.youtubeVideo')
        player.classList.remove('hidden');

    }
    const clickHandlerButtonRemove = (e) => {
        if (playerRef.current && playerRef.current.internalPlayer) {
            playerRef.current.internalPlayer.pauseVideo();
        }
        setIsOverlayVisible(false);
        setIsVideoPlaying(false);
        setVideoIdToPlay("");
        const player = document.querySelector('.youtubeVideo')
        player.classList.add('hidden')

    }
    const handleImageClick = (video) => {

        clickHandlerButtonShow(video);
    };


    const clickHandlerProjects = (e) => {
        const links = document.querySelectorAll('.projects--link');

        links.forEach(el => el.classList.remove('projects--link-active'));

        e.target.classList.add('projects--link-active');

        const sortedDataVideo = engineeringProjectsData.filter(el => {
            if (
                (el.type.split(' ').find(element => element === 'Machine-ProductDesign') && e.target.innerHTML === 'Machine-Product Design') ||
                (el.type.split(' ').find(element => element === 'FEA-CFDAnalysis') && e.target.innerHTML === 'FEA-CFD Analysis') ||
                (el.type.split(' ').find(element => element === 'ConceptDevelopment') && e.target.innerHTML === 'Concept Development') ||
                (el.type.split(' ').find(element => element === 'ProductRendering') && e.target.innerHTML === 'Product Rendering') ||
                (el.type.split(' ').find(element => element === '3DAnimations') && e.target.innerHTML === '3D Animations') ||
                (el.type.split(' ').find(element => element === 'ArchitecturalWalkthrough') && e.target.innerHTML === 'Architectural Walkthrough') ||
                (el.same === 'AllProjects' && e.target.innerHTML === 'All Projects')
            ) {
                return el; // Include the element in the filtered array
            }
            return null; // Exclude the element from the filtered array
        }).filter(el => el !== null); // Remove the null values from the array

        setData(sortedDataVideo);

        const sortedDataImage = engineeringProjectImage_data.filter(el => {
            if (
                (el.type.split(' ').find(element => element === 'Machine-ProductDesign') && e.target.innerHTML === 'Machine-Product Design') ||
                (el.type.split(' ').find(element => element === 'FEA-CFDAnalysis') && e.target.innerHTML === 'FEA-CFD Analysis') ||
                (el.type.split(' ').find(element => element === 'ConceptDevelopment') && e.target.innerHTML === 'Concept Development') ||
                (el.type.split(' ').find(element => element === 'ProductRendering') && e.target.innerHTML === 'Product Rendering') ||
                (el.type.split(' ').find(element => element === '3DAnimations') && e.target.innerHTML === '3D Animations') ||
                (el.type.split(' ').find(element => element === 'ArchitecturalWalkthrough') && e.target.innerHTML === 'Architectural Walkthrough') ||
                (el.type.split(' ').find(element => element === 'Electronics-CircuitDesign') && e.target.innerHTML === 'Electronics-Circuit Design') ||
                (el.same === 'AllProjects' && e.target.innerHTML === 'All Projects')
            ) {
                return el; // Include the element in the filtered array
            }
            return null; // Exclude the element from the filtered array
        }).filter(el => el !== null); // Remove the null values from the array

        setDataImg(sortedDataImage);



    }



    return (
        <section className="projects" id="projects">
            <div className="container">
                <div className="projects--links">


                    <div className='projects--link projects--link-active' onClick={clickHandlerProjects}>
                        All Projects
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Machine-Product Design
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        FEA-CFD Analysis
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Concept Development
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Product Rendering
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Electronics-Circuit Design
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        3D Animations
                    </div>
                    <div className='projects--link' onClick={clickHandlerProjects}>
                        Architectural Walkthrough
                    </div>
                </div>
                <div className="projects--data">
                    <div className="projects-empty"></div>
                    <div className="project--container">


                        {data.map((el, i) => {
                            return (
                                <div className={`projects--card ${el.type} ${el.same}`} key={i}>
                                    <div className='projects--card-image'>
                                        <YouTubeVideoEmbed videoId={el.videoLink} onImageClick={() => handleImageClick(el.videoLink)} />
                                    </div>
                                    <div
                                        className="projects--card-details" onClick={() => handleImageClick(el.videoLink)}>
                                        <h2>{el.title}
                                        </h2>
                                        <p>{el.para}</p>


                                    </div>
                                </div>
                            )
                        })}
                        {dataImg.map((el, i) => {
                            return (
                                <div className={`projects--card ${el.type} ${el.same}`} key={i}>
                                    <div className='projects--card-image'>
                                        <img src={el.image} alt=""
                                            onClick={() => ClickHandlerImageShow(el.imageData)}
                                        />
                                    </div>
                                    <div
                                        className="projects--card-details" onClick={() => clickHandlerButtonShow(el.videoLink)}>
                                        <h2>{el.title}
                                        </h2>
                                        <p>{el.para}</p>


                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            </div>
            <div className="filter-box" style={containerStyles}>
                <div className="filter-details" onClick={clickHandlerFilterButton}>
                    <VscSettings className='filter-icon' />
                    <div className='filter-title' >Filter</div>
                </div>
            </div>
            <div className={`youtubeVideo ${isOverlayVisible ? '' : 'hidden'}`} onClick={clickHandlerButtonRemove}>
                {isVideoPlaying && (
                    <YouTube
                        videoId={videoIdToPlay}
                        opts={{
                            width: '560',
                            height: '315',
                            playerVars: {
                                autoplay: 1,
                            },
                        }}
                        onReady={onPlayerReady}
                        onEnd={() => setIsVideoPlaying(false)}
                    />
                )}
                <button className="btn" onClick={clickHandlerButtonRemove}>
                    Close
                </button>

            </div>

        </section>
    )
}

export default ProjectsPage