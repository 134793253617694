import mainImage from '../../assests/img-media/main-1.png'


const mediaPageMain = {
    mainTitle: 'WE ARE A TEAM OF PROFESSIONAL ',
    mainPara: 'Welcome to our creative studio, where we are specialize in bringing your ideas to life through the power of visual storytelling. From video production and editing to graphic design and motion graphics, we offer a range of services to help you share your message with the world.',
    mainImage: mainImage,

    typeWriter: ["Video Editors", "Graphic Designers", "Animators"]

}

export default mediaPageMain