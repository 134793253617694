import React from 'react'
import './Contact.css'
import Navbar from '../../components/navbar/Navbar'
import ContactUsPage from './ContactUsPage'
import Footer from '../../components/footer/Footer'
import DesignLogo from '../../assests/logo/Vutuk-Logo_design.png'

import SwitchLogoMedia from '../../assests/logo/SwitchMedia.png'
import CTA from '../../components/cta/CTA'
import { Link } from 'react-router-dom'


const Contact = () => {
    return (
        <>
            <Navbar logo={DesignLogo}
                navlogo={'/design'}
                home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'} />

            <ContactUsPage />
            <Footer home={'/design'}
                about={'/design/about'}
                projects={'/design/projects'}
                contact={'/design/contact'}
                homeMedia={'/media'}
                homeDesign={'/design'}
                homeMain={'/'} />
            <Link to='/media'>
                <CTA logoImage={SwitchLogoMedia} />

            </Link>

        </>
    )
}

export default Contact